import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";
import customer from "./modules/customer";
import customerUser from "./modules/customerUser";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    customer,
    customerUser,
  },
  getters,
});

export default store;
