<template>
  <div id="app">
    <layout>
      <router-view />
    </layout>
  </div>
</template>

<script>
import Layout from "./views/Layout";

export default {
  components: {
    Layout,
  },
};
</script>

<style lang="scss">
html,
body,
#app {
  font-family: Microsoft YaHei, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 14px; /**默认字体大小 */
  color: #ffffff; /**默认字体颜色 */

  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
}

ul,
li {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
</style>
