<!--  -->
<template>
  <div class="organization_tree" :style="{ width: `${width}px` }">
    <el-tree
      ref="tree"
      :current-node-key="selectedId"
      :data="treeList"
      node-key="id"
      default-expand-all
      :highlight-current="true"
      @node-click="handleDepClick"
      :expand-on-click-node="false"
    >
      <!-- <span
        class="custom-tree-node"
        slot-scope="{ node, data }"
        @mouseenter="mouseenter(data, $event)"
        @mouseleave="mouseleave(data, $event)"
      >

        <span :title="data.label" :class="node.level === 1 ? 'fs-14' : 'fs-12'">
          {{ data.label }}
        </span>
        <span class="fr node none w-120">
          <svg-icon icon-class="add" @click="() => append(data, 'add')" />
          <svg-icon
            icon-class="edit"
            v-if="data.id != defaultId"
            @click="append(data, 'edit', node)"
          />
          <svg-icon
            icon-class="del"
            v-if="data.id != defaultId"
            @click="() => remove(node, data)"
          />
        </span>
      </span> -->
      <span
        slot-scope="{ node, data }"
        :class="[
          'dep-tree-node',
          node.level === 1 ? 'root-node' : 'common-node', //根节点还是普通节点的class
          { active: selectedId === data.id },
          { over: overTreeNode === data.id },
        ]"
        @mouseenter="overTreeNode = data.id"
        @mouseleave="overTreeNode = -1"
      >
        <span
          class="node-label"
          :title="node.label"
          :style="{
            width: `${computeLabelWidth(node)}px`,
          }"
          >{{ node.label }}</span
        >
        <span class="space"></span>
        <span
          class="node-operation"
          :style="{ display: [overTreeNode === data.id ? '' : 'none'] }"
        >
          <i
            class="el-icon-plus"
            v-if="node.level === 1||self.admin"
            @click.stop="() => edit(data, node, 'add')"
          ></i><!--root判断改为level判断,租户负责人永远显示-->
          <i
            class="el-icon-edit"
            v-if="node.level === 2||(self.admin&&node.level !== 1)"
            @click.stop="() => edit(data, node, 'edit')"
          ></i><!--租户负责人永远显示-->
          <i
            class="el-icon-delete"
            v-if="node.level === 2||(self.admin&&node.level !== 1)"
            @click.stop="() => remove(data, node, 'remove')"
          ></i><!--租户负责人永远显示-->

          <!-- <el-button
            type="text"
            size="mini"
            @click.stop="() => edit(data, node, 'add')"
          >
            添加
          </el-button>

          <el-button
            v-if="!data.root"
            type="text"
            size="mini"
            @click.stop="() => edit(data, node, 'edit')"
          >
            修改
          </el-button>
          <el-button
            v-if="!data.root"
            type="text"
            size="mini"
            @click.stop="() => remove(data, node, 'remove')"
          >
            删除
          </el-button> -->
        </span>
      </span>
    </el-tree>
    <el-dialog
      :title="title"
      :visible.sync="departmentVisible"
      width="580px"
      :before-close="closeAndReset"
    >
      <div class="dialog_form">
        <el-form
          :model="depForm"
          ref="depForm"
          :rules="rules"
          label-width="100px"
          class="dep-form"
        >
          <el-form-item
            prop="name"
            label="部门名称"
          >
            <el-input v-model="depForm.name" :maxlength="30"></el-input>
          </el-form-item>

          <el-form-item
            label="上级部门"
            prop="parentId"
          >
            <q-select
              :schema="{
                xType: 'q-select',
                placeholder: '请选择上级部门',
                disabled:true,
                opts: depOpts,
              }"
              v-model="depForm.parentId"
            />
          </el-form-item>

          <el-form-item label="部门logo" prop="logo"
          >
            <el-upload
                    :class="{hide:hideUpload}"
                    action="/api/file/upload"
                    list-type="picture-card"
                    :file-list="imagelist"
                    :limit="1"
                    :on-change="imgChange"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                    :on-remove="handleRemove"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>

          <el-form-item label="有效期" prop="termOfValidity" v-if="hideUpload"
          >
            <el-date-picker
                    v-model="depForm.termOfValidity"
                    type="date"
                    placeholder="选择有效期"
                    :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
          <!-- <el-form-item
            prop="parentId"
            label="上级部门"
            :rules="[
              { required: true, message: '请选择上级部门', trigger: 'blur' },
            ]"
          >
            <el-select
              v-model="depForm.parentId"
              placeholder="请选择上级部门"
              class="wb-100"
            >
              <el-option
                v-for="item in depOpts"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handelSubmit()">确 定</el-button>
        <el-button @click="closeAndReset()">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import {
  departmentTree,
  addDepartment,
  editDepartment,
  delDepartment,
  departmentList,
} from "@/api/departmentApi";

import { convertTree } from "@/utils/common";
import moment from 'moment'
import BizCode from "@/utils/BizCode";
import { mapState } from "vuex";

export default {
  name: "OrganizationTree",
  props: {
    //选中的树节点的Id
    selectedId: {
      type: Number,
      default: () => -1,
    },
    width: {
      type: Number,
      default: () => {
        return 350;
      },
    },
  },
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
      const needLogo = (rule, value, callback) => {
          if (value !== '' && value !== null) {
              this.rules.termOfValidity[0].required = true
              callback()
          } else {
              this.rules.termOfValidity[0].required = false
              callback()
          }
      }
      const needTime = (rule, value, callback) => {
          if (value !== null && value !== '') {
              this.rules.logo[0].required = true
              callback()
          } else {
              this.rules.logo[0].required = false
              callback()
          }
      }
    return {
        pickerOptions: {
            disabledDate: (time) => {
                return time.getTime() < Date.now() - 8.64e7;
            },
        },
        rules: {
            logo: [
                { required: false, message: '部门logo不能为空', trigger: 'change' },
            ],
            termOfValidity: [
                { required: true, message: '有效期不能为空', trigger: 'change' },
            ],
            parentId: [
                { required: true, message: '请选择上级部门', trigger: 'change' },
            ],
            name: [
                { required: true, message: '部门名称不能为空', trigger: 'blur' },
            ]
        },
      overTreeNode: -1, //鼠标悬停选中
      treeList: [],
      departmentList: [],
      imagelist: [],
        imageUrl: "",
        hideUpload: false,
        limitCount:1,
      depOpts: [], //这里不能用departmentList否则会引用错乱
      title: "新建下级部门",
      departmentVisible: false,
      //defaultId: "",
      depForm: {
        id: "",
        parentId: "",
        name: "",
        logo: null,
          termOfValidity: ''
      },
    };
  },

  async mounted() {
    await this.fetchData();
  },

  //方法集合
  methods: {
    //根据node的层级level计算Label的宽度
    computeLabelWidth(node) {
      const treeWidth = this.width; //树的宽度
      const indent = 18; //每行缩进
      const levelFrom0 = node.level - 1; //从0开始的层级号
      const downArrowWidth = 24; //下拉箭头的宽度
      const operationEveryButtonWidth = 40; //操作栏每个按钮的宽度
      const operationWidthRoot = operationEveryButtonWidth * 1; //根节点的操作盘宽度(只有一个按钮)
      const operationWidthCommon = operationEveryButtonWidth * 3; //普通节点的操作盘宽度(3个按钮)
      return (
        treeWidth -
        downArrowWidth -
        indent * levelFrom0 -
        (levelFrom0 === 0 ? operationWidthRoot : operationWidthCommon)
      );
    },
    //没有选中的节点才需要执行
    setDefaultSelected() {
      const defaultSelectedId = this.treeList?.[0]?.id;
      if (defaultSelectedId !== null && defaultSelectedId !== undefined) {
        this.$emit("update:selectedId", defaultSelectedId,1);//选中根节点
      }
    },
    //获取树形结构的数据
    async fetchData() {
      const [depTree, depList] = await Promise.all([
        departmentTree(),
        departmentList(),
      ]);

      //树形结构的部门
      if (depTree.code === BizCode.OK) {
        const { data } = depTree;
        //将后台传过来的组织结构树转换成符合前端表现得形式
        const tree = convertTree(data, (node) => {
          return {
            id: node.data.id,
            label: node.data.name,
            mcid: node.data.mcid,
            root: node.data.root,
              logo:node.data.logo,
              termOfValidity: node.data.termOfValidity,
            children: [],
          };
        });
        this.treeList = [tree];
      }

      //列表型结构的部门
      if (depList.code === BizCode.OK) {
        const { data } = depList;
        this.departmentList = data;
      }

      //没有选中的节点
      //或选中节点不被包含在数据中(比如已删除)
      //才需要执行
      if (
        this.selectedId <= 0 ||
        !this.departmentList.map((d) => d.id).includes(this.selectedId)
      ) {
        this.setDefaultSelected();
      }
    },

    //部门选择事件
    handleDepClick(data, node, component) {
      //这样就可以了
      //console.log(node,'node......')
      this.$emit("update:selectedId", data.id,node.level);
    },

      /** 删除 */
      handleRemove(file,fileList) {
          this.depForm.logo = null;
          this.depForm.termOfValidity = null;
          this.imagelist = []
          this.hideUpload = fileList.length === 1;
      },

      imgChange(file, fileList){
          this.hideUpload = fileList.length === 1;
      },
      /** s上传图片 */
      handleAvatarSuccess(res) {
          this.depForm.logo = res.data.url;
          this.imagelist.push({ name: res.data.url, url: res.data.url });
      },
      beforeAvatarUpload(file) {
          const isLt50M = file.size / 1024 / 1024 < 50;
          var index = file.name.lastIndexOf(".");
          const str = file.name.substring(index + 1, file.name.length);
          if (["jpg", "jpeg", "gif", "bmp", "png"].indexOf(str) === -1) {
              this.$message.error("支持*.jpg;*.jpeg;*.gif;*.bmp;*.png;格式");
              return false;
          }
          if (!isLt50M) {
              this.$message.error("上传图片大小不能超过 50MB!");
          }
          return isLt50M;
      },

    async remove(data, node, mode) {
      try {
        await this.$confirm("是否确认删除？", "删除", {
          cancelButtonClass: "btn-custom-cancel",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
        //调用后台删除接口
        const result = await delDepartment(data.id);
        if (result.code === BizCode.OK) {
          this.$message.success("删除成功");
          //重新加载数据
          await this.fetchData();
          // //若删除的是当前选中节点,则重新设置默认选中节点
          // if (data.id === this.selectedId) {
          //   this.setDefaultSelected();
          // }
        }
      } catch (err) {
        //非取消引发的异常直接抛出
        if (err !== "cancel") {
          throw err;
        }
      }
    },

    //add or update
    edit(data, node, mode) {
      //   打开弹框
      this.departmentVisible = true;
      if (mode === "add") {
        this.title = "新建下级部门";
        this.depForm.name = "";
        this.depForm.termOfValidity = null;
        this.depForm.logo = null;
        this.depForm.parentId = data.id;
        this.depOpts = this.departmentList.map((dep) => {
          return { label: dep.name, value: dep.id };
        });
          this.hideUpload = false
      } else {
          console.log(data)
          console.log(node)
        this.title = "修改部门";
        this.depForm.id = data.id; //修改部门 id即为 data id
        this.depForm.name = data.label;
        this.depForm.parentId = node.parent.data.id; //node主要是为了获取其父节点
          this.depForm.logo = data.logo;
          this.depForm.termOfValidity = data.termOfValidity
          if (this.depForm.logo !== null && this.depForm.logo !== '') {
              this.imagelist.push({
                  name: data.logo,
                  url: data.logo
              })
              this.hideUpload = true
          } else {
              this.hideUpload = false
          }
          console.log(this.depForm)
        //上级部门不能选自己
        this.depOpts = this.departmentList
          .filter((dep) => dep.id !== data.id)
          .map((dep) => {
            return { label: dep.name, value: dep.id };
          });
      }
    },

    handelSubmit() {
          console.log(111)
      this.$refs["depForm"].validate(async (valid) => {
          console.log(222)
          console.log(valid)
        if (valid) {
          const { id, name, parentId,logo, termOfValidity } = this.depForm;
            console.log(termOfValidity)
          if (this.title === "新建下级部门") {
              console.log(this.depForm)
            const result = await addDepartment({ name, parentId,logo,termOfValidity });
            if (result.code === BizCode.OK) {
              this.$message.success("新增成功");
              //关闭弹框并重置表单
              this.closeAndReset();
              //重新渲染树
              await this.fetchData();
            }
          } else {
            const result = await editDepartment(id, { name, parentId,logo,termOfValidity  });
            if (result.code === BizCode.OK) {
              this.$message.success("修改成功");
              //关闭弹框并重置表单
              this.closeAndReset();
              //重新渲染树
              await this.fetchData();
            }
          }
        }
      });
    },
    /** 关闭弹框并重置表单1 */
    closeAndReset() {
      //重置所有表单字段
      this.depForm = {
        id: "",
        parentId: "",
        name: "",
          logo: null,
          termOfValidity: null
      };
      this.imagelist = []
      if (this.$refs["depForm"]) {
        this.$refs["depForm"].resetFields();
      }
      this.depOpts = []; //清空部门选项
      this.departmentVisible = false;
    },
  },

  computed: mapState({
    company: (state) => state.customer.self,
    self: (state) => state.customerUser.self,
  }),
  watch: {
    selectedId: {
      // eslint-disable-next-line no-unused-vars
      handler(val, oldVal) {
        //同步选中节点和element tree的选中节点
        this.$nextTick(() => {
          this.$refs["tree"].setCurrentKey(val);
        });
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
$node_height: 40px;
$operation_button_width: 40px;

.el-tree .el-tree-node__content {
  height: $node_height;
  line-height: $node_height;
}

.hide .el-upload--picture-card {
  display: none !important;
}

.dep-tree-node {
  width: 100%;
  height: $node_height;
  line-height: $node_height;
  display: flex;
  .node-label {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .node-operation {
    //width: 100px;//操作栏的宽度,这里是靠计算算出来的
    i {
      width: $operation_button_width;
      text-align: center;
      font-size: 18px;
    }
  }
}
</style>
