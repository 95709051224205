<template>
  <div>
    <el-form @submit.native.prevent :inline="true" :model="formData" class="demo-form-inline">
      <el-form-item label="信息搜索">
        <el-input
          style="width:300px"
          @keyup.enter.native="onSubmit"
          v-model="formData.key"
          placeholder="搜权限组名称关键词"
        ></el-input>
      </el-form-item>
      <el-form-item label="应用">
        <q-select
          @keyup.enter.native="onSubmit"
          :schema="{
            xType: 'q-select',
            placeholder: '全部',
            remoteOnce: getAllApplication,
          }"
          v-model="formData.appId"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import api from "@/api/application";
import BizCode from "@/utils/BizCode";

export default {
  data() {
    return {
      formData: {
        key: "",
        appId: null,
      },
    };
  },
  methods: {
    onSubmit() {
      //console.log("submit!");
      this.$emit("onSearch", this.formData);
    },
    async getAllApplication() {
      const result = await api.list();
      if (result.code === BizCode.OK) {
        return (
          (result.data ?? [])
            //.filter((m) => m.subscribed)
            .map((m) => {
              return { label: m.name, value: m.appId };
            })
        );
      }
      return [];
    },
  },
  //还原query参数
  watch: {
    "$route.query": {
      handler(p) {
        // this.searchForm.page = parseInt(p.page || 1);
        // this.searchForm.key = p.key || '';
        // this.searchForm.classification = p.classification || '';
        // this.searchForm.status = p.status || '';
        // this.doSearchForm = { ...this.searchForm };
        // this.currentPage = parseInt(p.page ?? 1);
        // this.pageSize = parseInt(p.size ?? 5);

        let queryParams = {};
        if (p?.queryParams !== null && p?.queryParams !== undefined) {
          queryParams = JSON.parse(window.decodeURI(p.queryParams));
        } else {
          queryParams = {};
        }

        const { key = "", appId = null } = queryParams;
        this.formData = {
          key,
          appId,
        };

        //this.setOrderParams(p.orderParams ?? {});
        //this.fetchData();
        //this.syncServer(); //获取数据
      },
      immediate: true, //立即执行一次需要
    },
  },
};
</script>
