import _ from "lodash";
import { gsap } from "gsap";

 //享元模式防抖
export default class FlipPlayer {
    static map = new Map();
    static flyweight(element) {
      if (FlipPlayer.map.has(element)) {
        return FlipPlayer.map.get(element);
      } else {
        const f = new FlipPlayer(element);
        FlipPlayer.map.set(element, f);
        return f;
      }
    }
    element = "";
    debounce = _.debounce(this.flip, 450, {
      leading: true,
      trailing: false,
    });
  
    constructor(element) {
      this.element = element;
    }
  
    flip() {
      const element=this.element;
      const tl = gsap.timeline();
      tl.fromTo(
        element,
        {
          transform: "perspective(400px) rotate3d(0, 1, 0, 90deg)",
          "animation-timing-function": "ease-in",
          opacity: 0,
        },
        {
          transform: "perspective(400px) rotate3d(0, 1, 0, -20deg)",
          "animation-timing-function": "ease-in",
          opacity: 0.6,
          duration: 0.4,
        }
      )
        .to(element, {
          transform: "perspective(400px) rotate3d(0, 1, 0, 10deg)",
          opacity: 1,
          duration: 0.2,
        })
        .to(element, {
          transform: "perspective(400px) rotate3d(0, 1, 0, -5deg)",
          duration: 0.2,
        })
        .to(element, {
          transform: "perspective(400px)",
          duration: 0.2,
          clearProps: "all",
        });
    }
  
    debounceFlip() {
      this.debounce();
    }
  }
  