var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-subject',{attrs:{"subject":"权限组设置"}},[_c('div',{staticClass:"form-panel"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"150px"}},[_c('el-form-item',{attrs:{"label":"权限组名称","prop":"name","rules":[
          {
            required: true,
            message: '权限组名称不能为空',
            trigger: 'change',
          },
        ]}},[_c('el-input',{attrs:{"maxlength":30,"placeholder":"请输入权限组名称"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{attrs:{"label":"应用","prop":"appId","rules":[
          { required: true, message: '请选择应用', trigger: 'change' },
        ]}},[_c('q-select',{attrs:{"schema":{
            xType: 'q-select',
            placeholder: '请选择应用',
            remoteOnce: _vm.getAllApplication,
            onSelectChange: _vm.onApplicationSelect,
          }},model:{value:(_vm.form.appId),callback:function ($$v) {_vm.$set(_vm.form, "appId", $$v)},expression:"form.appId"}})],1),_c('el-form-item',{ref:"fieldResources",attrs:{"label":"选择菜单","prop":"resources","rules":[
          { required: true, message: '请选择菜单', trigger: 'change' },
        ]}},[_c('q-checkbox-group',{attrs:{"schema":{ opts: this.opts },"placeholder":"请选择菜单"},model:{value:(_vm.form.resources),callback:function ($$v) {_vm.$set(_vm.form, "resources", $$v)},expression:"form.resources"}})],1)],1),_c('div',{staticClass:"form-button"},[(_vm.$route.query.mode !== 'view')?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onSubmit}},[_vm._v("确定")]):_vm._e(),_c('el-button',{on:{"click":function($event){return _vm.$back()}}},[_vm._v("返回")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }