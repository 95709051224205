<template>
  <left
    selectedSubject="air"
    :machineImage="image"
    :projectTitleImage="require('@/assets/style/images/air.png')"
    :application="application"
    :applicationList="applicationList"
  >
    <div class="details">
      <div class="project-introduction opactiycard">
        <div class="project-caption">项目介绍</div>
        <div class="project-content">
          为工业制造业的设备管理提供一整套包含软件、硬件和实施服务的基于公有云IoT智能架构的新一代物联网联控整体解决方案。为制造商、服务商和终端用户提供中立第三方的物联感知和基于AI人工智能的设备管控服务，达到工业设备的能耗可视化、管理精细化、运维信息化、状态健康化和报告智能化。
        </div>
      </div>
      <div class="project-items opactiycard">
        <div class="project-item">
          <div class="item-caption">产品使命</div>
          <div class="item-content intro1">
            <div class="feat">
              <div class="feat-item">【用气用能的智能化】</div>
              <div class="feat-item ball-line ball-blue">
                <div></div>
              </div>

              <div class="feat-item">
                科学的、工程学的高效能源使用，从无系统→系统+人→系统+AI的智能化迈进
              </div>
            </div>
            <div class="feat-space"></div>
            <div class="feat">
              <div class="feat-item">【产气设备的健康化】</div>
              <div class="feat-item ball-line ball-blue">
                <div></div>
              </div>
              <div class="feat-item">
                保证产气设备健康运行，促进从被动服务向主动服务的转型升级
              </div>
            </div>
          </div>
        </div>
        <div class="project-item">
          <div class="item-caption">物联服务</div>
          <div class="item-content intro2">
            <div>
              <img
                src="@/assets/style/images/projectitems/iotservice.png"
                width="100%"
              />
            </div>
            <div class="line gray"></div>
            <div class="intro2-description">
              本产品为空压机行业提供一整套包含软件、硬件和实施服务的基于公有云IoT智能架构的新一代物联网联控整体解决方案。
              为用户提供中立第三方的物联感知和基于AI人工智能的能效管控服务，达到空压站的能耗可视化、管理精细、运维信息化、设备健康化和报告智能化。
            </div>
          </div>
        </div>
        <div class="project-item">
          <div class="item-caption">数字站房</div>
          <div class="item-content intro3">
            <div>
              <img
                src="@/assets/style/images/projectitems/datastation.png"
                width="95%"
              />
            </div>
            <!-- <div class="datastation-space"></div> -->
            <ul class="featlist">
              <li>
                <i class="icon iconfont icon-shishijiance" />
                <span> 全局监控 </span>
              </li>
              <li>
                <i class="icon iconfont icon-pichuli" />
                <span> 数据处理 </span>
              </li>
              <li>
                <i class="icon iconfont icon-zhuisu" />
                <span> 数据追溯 </span>
              </li>
              <li>
                <i class="icon iconfont icon-biaodan" />
                <span> 实时数据 </span>
              </li>
              <li>
                <i class="icon iconfont icon-baobiao" />
                <span> 数据报表 </span>
              </li>
              <li>
                <i class="icon iconfont icon-baojing" />
                <span> 报警分析 </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="project-item">
          <div class="item-caption">智能控制</div>
          <div class="item-content intro4">
            <div>
              <img
                src="@/assets/style/images/projectitems/ai.png"
                width="100%"
              />
            </div>
            <ul class="feat-ai">
              <li>
                <div class="feat-ai-caption">
                  <i class="feat-ai-point"></i>
                  <span>全类型设备 空压机</span>
                </div>
                <div class="feat-ai-content">
                  空压机、后处理、附属设备、 仪器仪表、阀门等 反向执行
                  空压机、干燥机、冷却水系统联动控制设置及自动控制 一键
                </div>
              </li>
              <li>
                <div class="feat-ai-caption">
                  <i class="feat-ai-point"></i>
                  <span>全类型设备 空压机</span>
                </div>
                <div class="feat-ai-content">
                  空压机、后处理、附属设备、 仪器仪表、阀门等 反向执行
                  空压机、干燥机、冷却水系统联动控制设置及自动控制 一键
                </div>
              </li>
              <li>
                <div class="feat-ai-caption">
                  <i class="feat-ai-point"></i>
                  <span>全类型设备 空压机</span>
                </div>
                <div class="feat-ai-content">
                  空压机、后处理、附属设备、 仪器仪表、阀门等 反向执行
                  空压机、干燥机、冷却水系统联动控制设置及自动控制 一键
                </div>
              </li>
              <li>
                <div class="feat-ai-caption">
                  <i class="feat-ai-point"></i>
                  <span>全类型设备 空压机</span>
                </div>
                <div class="feat-ai-content">
                  空压机、后处理、附属设备、 仪器仪表、阀门等 反向执行
                  空压机、干燥机、冷却水系统联动控制设置及自动控制 一键
                </div>
              </li>
            </ul>
            <ul class="feat-ai">
              <li>
                <div class="feat-ai-caption">
                  <i class="feat-ai-point"></i>
                  <span>全类型设备 空压机</span>
                </div>
                <div class="feat-ai-content">
                  空压机、后处理、附属设备、 仪器仪表、阀门等 反向执行
                  空压机、干燥机、冷却水系统联动控制设置及自动控制 一键
                </div>
              </li>
              <li>
                <div class="feat-ai-caption">
                  <i class="feat-ai-point"></i>
                  <span>全类型设备 空压机</span>
                </div>
                <div class="feat-ai-content">
                  空压机、后处理、附属设备、 仪器仪表、阀门等 反向执行
                  空压机、干燥机、冷却水系统联动控制设置及自动控制 一键
                </div>
              </li>
              <li>
                <div class="feat-ai-caption">
                  <i class="feat-ai-point"></i>
                  <span>全类型设备 空压机</span>
                </div>
                <div class="feat-ai-content">
                  空压机、后处理、附属设备、 仪器仪表、阀门等 反向执行
                  空压机、干燥机、冷却水系统联动控制设置及自动控制 一键
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="project-item">
          <div class="item-caption">健康管理</div>
          <div class="item-content intro5">
            <div class="health">
              <img
                src="@/assets/style/images/projectitems/health.png"
                width="100%"
              />
            </div>
            <ul class="feat-health">
              <li>
                <div class="feat-health-num">
                  <div class="feat-health-num-ball"></div>
                  <div class="feat-health-num-code">01</div>
                </div>
                <div class="feat-health-caption">提高关键设备的可靠性</div>
                <div class="feat-health-content">
                  通过点检对关键设备运行状态进行监测，将故障消除在萌芽状态。
                </div>
              </li>
              <li>
                <div class="feat-health-num">
                  <div class="feat-health-num-ball"></div>
                  <div class="feat-health-num-code">02</div>
                </div>
                <div class="feat-health-caption">降低设备维修成本</div>
                <div class="feat-health-content">
                  通过定期点巡检管理，使用户可以针对性的进行设备维修。
                </div>
              </li>
              <li>
                <div class="feat-health-num">
                  <div class="feat-health-num-ball"></div>
                  <div class="feat-health-num-code">03</div>
                </div>
                <div class="feat-health-caption">规范点检过程管理</div>
                <div class="feat-health-content">
                  对巡检员定出详细的操作步骤，避免了传统点检方式的随意性，保证了工作质量。
                </div>
              </li>
              <li>
                <div class="feat-health-num">
                  <div class="feat-health-num-ball"></div>
                  <div class="feat-health-num-code">04</div>
                </div>
                <div class="feat-health-caption">实现由“修”到“管”的转变</div>
                <div class="feat-health-content">
                  从设备坏了再修转变为设备的预知检修，提高了设备利用率，缩短了检修工期。
                </div>
              </li>
              <li>
                <div class="feat-health-num">
                  <div class="feat-health-num-ball"></div>
                  <div class="feat-health-num-code">05</div>
                </div>
                <div class="feat-health-caption">
                  提高工作效率，降低生产成本
                </div>
                <div class="feat-health-content">
                  通过点检管理使得全厂巡点检标准化；避免无计划的盲目检修。
                </div>
              </li>
              <li>
                <div class="feat-health-num">
                  <div class="feat-health-num-ball"></div>
                  <div class="feat-health-num-code">06</div>
                </div>
                <div class="feat-health-caption">有效监管设备的检修过程</div>
                <div class="feat-health-content">
                  从点检结果转向设备维修，过程无缝集成；实现了设备检修过程的规范化要求。
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="project-item">
          <div class="item-caption">丹碧德简介</div>
          <div class="item-content intro6">
            <div>
              丹碧德是专业从事空压系统节能服务的技术型高新企业，成立于2008年，公司位于上海市松江区G60科创云廊，拥有一批有责任心、有核心技术力量的专业团队。一直坚持以客户为中心的经营理念，以质量保障为根本，以节能环保为目标，用数十年的专业领域经验，致力为客户提供最佳的定制化解决方案，以过硬的质量和良好的效果赢得客户的信任和托付，多年来在纺织、汽配、水泥、医药、发电等行业积累了超过2000家客户，如：中国中车、晓星化纤、华域汽车、上海电气等。
            </div>
            <div>
              <img
                src="@/assets/style/images/projectitems/intro6.png"
                width="100%"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </left>
</template>

<script>
import Left from "@/views/Left";
import api from "@/api/application";
import BizCode from "@/utils/BizCode";

export default {
  name: "Home",
  components: {
    Left,
  },
  mounted() {
    console.log(this.$route.params?.appId, "appId.........");
    Promise.all([
      this.loadApplication(this.$route.params?.appId),
      this.loadApplicationList(),
      this.getPic(this.$route.params?.appId),
    ]);
  },
  destroyed() {},
  data() {
    return {
      applicationList: [],
      application: {},
      image: "",
    };
  },
  methods: {
    // 获取用户图片
    async getPic(appId) {
      await api.logo(appId).then((r) => {
        if (r.data.logo !== "") {
          this.image = r.data.logo;
        } else {
          this.image = require("@/assets/style/images/machine.png");
        }
      });
    },
    //加载应用列表
    async loadApplicationList() {
      let result = await api.list({ subscribed: null });
      if (result.code === BizCode.OK) {
        //列表
        const list = result?.data ?? [];
        this.applicationList = [...list];
      }
    },
    //读取应用程序相关信息
    async loadApplication(appId) {
      const result = await api.detail(appId);
      if (result.code === BizCode.OK) {
        console.log(result.data, "data......");
        this.application = { ...result.data };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  color: rgba(180, 198, 225, 1);
  .project-introduction {
    @extend .deepcard;
    padding: 32px;
    line-height: 24px;

    .project-caption {
      font-size: 16px;
    }
    .project-content {
      margin-top: 24px;
      text-indent: 2em;
    }
  }

  .project-items {
    @extend .deepcard;
    margin-top: 10px;
    padding: 0px 60px 0px 60px;
    .project-item {
      padding: 30px 40px 30px 40px;
      border-bottom-style: solid;
      border-bottom-width: 1px;
      border-bottom-color: rgba(255, 255, 255, 0.2);
      .item-caption {
        font-size: 24px;
        color: rgba(231, 240, 255, 1);
        font-weight: bold;
        text-align: center;
        // height: 120px;
        // line-height: 120px;
        //margin-bottom: 50px;
      }
      .item-content {
        margin-top: 50px;
      }
      .intro1 {
        display: flex;
        justify-content: space-around;
        .feat-space {
          //flex: auto;
          flex-basis: 50px;
          flex-grow: 1;
          flex-shrink: 0;
        }
        .feat {
          .feat-item {
            margin-bottom: 10px;
          }
        }
      }
      .intro2 {
        div:nth-of-type(1) {
          text-align: center;
        }
        div:nth-of-type(n + 2) {
          margin-top: 30px;
        }
        .intro2-description {
          text-indent: 2em;
        }
      }
      .intro3 {
        display: flex;
        justify-content: center;

        .featlist {
          width: 270px;
          height: 400px;
          flex-shrink: 0;
          flex-grow: 0;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: center;
          align-content: flex-start;

          li {
            width: 109px;
            height: 98px;
            margin: 10px;
            border: 1px solid #0068ff;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            i {
              color: rgba(0, 104, 255, 1);
              font-size: 30px;
            }
            span {
              //height: 40px;
              //line-height: 40px;
              margin-top: 5px;
              color: rgba(255, 255, 255, 1);
            }
          }
        }
        // .datastation-space {
        //   //flex: auto;
        //   flex-basis: 200px;
        //   flex-grow: 0;
        //   flex-shrink: 0;
        // }
      }

      .intro4 {
        display: flex;
        .feat-ai {
          margin-right: 20px;
          li {
            margin-top: 20px;
            .feat-ai-point {
              //标题前面的蓝色点
              width: 2px;
              height: 2px;
              margin-right: 3px;
              display: inline-block;
              vertical-align: middle;
              background-color: rgba(0, 104, 255, 1);
            }
            .feat-ai-caption {
              color: rgba(0, 104, 255, 1);
              line-height: 24px;
            }
            .feat-ai-content {
              //color: rgba(0, 104, 255, 1);
              line-height: 24px;
            }
          }
        }
      }

      .intro5 {
        display: flex;
        flex-direction: column;
        align-items: center;
        .health {
          width: 80%;
        }
        .feat-health {
          margin-top: 30px;
          width: 720px;
          display: flex;
          flex-wrap: wrap;
          li {
            margin: 15px;
            width: 210px;
            min-height: 220px;
            //background-color: red;
            .feat-health-num {
              position: relative;
              margin: 0px auto;
              width: 70px;
              height: 66px;
              //background-color: red;
              .feat-health-num-code {
                font-size: 48px;
                position: absolute;
                top: 0;
                left: 0;
              }
              .feat-health-num-ball {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 37px;
                height: 37px;
                background: linear-gradient(
                  144deg,
                  #0068ff 0%,
                  rgba(0, 104, 255, 0.17) 100%
                );
                border-radius: 50%;
              }
            }

            .feat-health-caption {
              margin-top: 20px;
              height: 24px;
              font-size: 14px;
              font-weight: bold;
              color: #0068ff;
              line-height: 24px;
            }
            .feat-health-content {
              font-weight: 400;
              line-height: 24px;
            }
          }
        }
      }
      .intro6 {
        div:nth-of-type(1) {
          //text-align: center;
          text-indent: 2em;
        }
        div:nth-of-type(n + 2) {
          margin-top: 30px;
        }
      }
    }
  }
}
</style>
