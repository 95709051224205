<template>
  <div class="q-data-grid">
    <el-table :loading="loading" :data="tableData">
      <slot> </slot>
    </el-table>
    <el-pagination
      v-if="schema.pagingEnable"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import BizCode from "@/utils/BizCode";
import { isPromise } from "@/utils/index";

export default {
  name: "QGridView",
  props: {
    schema: {
      type: Object,
      required: true,
    },
  },
  async mounted() {
    //挂载事件
    // if (this.schema.autoLoad ?? true) {
    //   this.syncServer();
    // }
  },

  data() {
    return {
      tableData: this.data,
      total: 0,
      currentPage: 1,
      pageSize: 10,
      loading: false,
      queryParams: {},
      orderParams: {},
    };
  },
  methods: {
    handleSizeChange(val) {
      //console.log(`每页 ${val} 条`);
      this.pageSize = val;
      //this.syncServer();
      this.changeRoute();
      if (this.schema?.autoLoad ?? true) {
        //
      } else {
        //即使手动控制这里也需要自动获取数据
        this.syncServer(); //获取数据
      }
    },
    handleCurrentChange(val) {
      //console.log(`当前页: ${val}`);
      this.currentPage = val;
      //this.syncServer();
      this.changeRoute();
      if (this.schema?.autoLoad ?? true) {
        //
      } else {
        //即使手动控制这里也需要自动获取数据
        this.syncServer(); //获取数据
      }
    },
    //设置查询参数
    setQueryParams(query) {
      this.queryParams = { ...query };
      this.changeRoute();
    },

    async syncServer() {
      if (this.schema.pagingEnable) {

        console.log(this.currentPage,'this.currentPage')
        console.log(this.pageSize,'this.pageSize')
        //支持分页的逻辑
        const { getPageList = () => {} } = this.schema;
        console.log(this.queryParams, "this.queryParams");
        let result = getPageList({
          page: this.currentPage,
          size: this.pageSize,
          ...this.queryParams,
          ...this.orderParams,
        });
        if (isPromise(result)) {
          //this.loading = true;
          result = await result;
          //this.loading = false;
        }
        if (result?.code === BizCode.OK) {
          const { data } = result;
          this.total = data.total;
          this.tableData = data.records;
        }
      } else {
        //不支持分页的逻辑
        const { getList = () => {} } = this.schema;

        let result = getList();

        if (isPromise(result)) {
          //this.loading = true;
          result = await result;
          //this.loading = false;
        }

        console.log(result, "result............");
        if (result?.code === BizCode.OK) {
          const { data } = result;
          this.tableData = data;
        }
      }
    },

    //改变路由change route
    changeRoute() {
      console.log("change");
      // eslint-disable-next-line no-unused-vars
      const { page, size, queryParams, t, ...other } = this.$route.query ?? {}; //其他不相干参数也不能丢需要保持住
      this.$router.push({
        path: this.$route.path,
        query: {
          page: this.currentPage,
          size: this.pageSize,
          queryParams: window.encodeURI(JSON.stringify(this.queryParams ?? {})),
          //orderParams: this.orderParams,
          t: new Date().getTime(),
          ...other,
        },
      });
    },
  },

  //还原query参数
  watch: {
    "$route.query": {
      handler(p) {
        // this.searchForm.page = parseInt(p.page || 1);
        // this.searchForm.key = p.key || '';
        // this.searchForm.classification = p.classification || '';
        // this.searchForm.status = p.status || '';
        // this.doSearchForm = { ...this.searchForm };
        this.currentPage = parseInt(p.page ?? 1);
        this.pageSize = parseInt(p.size ?? 10);

        if (p?.queryParams !== null && p?.queryParams !== undefined) {
          this.queryParams = JSON.parse(window.decodeURI(p.queryParams));
        } else {
          this.queryParams = {};
        }

        //this.setOrderParams(p.orderParams ?? {});
        //this.fetchData();
        //否则手动控制

        if (this.schema?.autoLoad ?? true) {
          this.syncServer(); //获取数据
        }
      },
      immediate: true, //立即执行一次需要
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
