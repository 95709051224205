var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-subject',{attrs:{"subject":"完善基本信息"}},[_c('div',{staticClass:"form-panel"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"150px"}},[(!_vm.form.customerInfoComplete)?_c('div',{staticStyle:{"padding-left":"150px","margin-bottom":"40px"}},[_vm._v(" 首次登陆，需确认并完善企业信息！ ")]):_vm._e(),_c('el-form-item',{attrs:{"label":"公司名称","prop":"name","rules":[
          { required: true, message: '请输入公司名称', trigger: 'change' },
        ]}},[_c('el-input',{attrs:{"placeholder":"请输入公司名称"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{attrs:{"label":"公司简称","prop":"simpleName","rules":[
          { required: true, message: '请输入公司简称', trigger: 'change' },
        ]}},[_c('el-input',{attrs:{"placeholder":"请输入公司简称"},model:{value:(_vm.form.simpleName),callback:function ($$v) {_vm.$set(_vm.form, "simpleName", $$v)},expression:"form.simpleName"}})],1),_c('el-form-item',{attrs:{"label":"负责人","prop":"managerName","rules":[
          { required: true, message: '请输入负责人', trigger: 'change' },
        ]}},[_c('el-input',{attrs:{"placeholder":"请输入负责人"},model:{value:(_vm.form.managerName),callback:function ($$v) {_vm.$set(_vm.form, "managerName", $$v)},expression:"form.managerName"}})],1),_c('el-form-item',{attrs:{"label":"负责人手机","prop":"managerPhone","rules":[
          {
            required: true,
            message: '请输入负责人手机',
            trigger: 'change',
          },
        ]}},[_c('el-row',[_c('el-col',{attrs:{"span":7}},[_vm._v(" "+_vm._s(_vm.form.managerPhone)+" ")])],1)],1),_c('el-form-item',{attrs:{"label":"企业邮箱","prop":"email","rules":[
          {
            type: 'email',
            message: '请输入正确的邮箱地址',
            trigger: 'change',
          },
        ]}},[_c('el-input',{attrs:{"placeholder":"请输入企业邮箱"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('el-form-item',{attrs:{"label":"公司电话","prop":"phone","rules":[
          {
            pattern: _vm.regexs.phoneRegWithArea2,
            message: '请输入正确的电话',
            trigger: 'change',
          },
        ]}},[_c('el-input',{attrs:{"placeholder":"请输入公司电话"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_c('el-form-item',{attrs:{"label":"社会统一信用代码","prop":"uscc","rules":[
          {
            required: true,
            message: '请输入社会统一信用代码',
            trigger: 'change',
          },
          {
            pattern: _vm.regexs.uscc,
            message: '请输入正确的社会统一信用代码',
            trigger: 'change',
          },
        ]}},[_c('el-input',{attrs:{"placeholder":"请输入社会统一信用代码"},model:{value:(_vm.form.uscc),callback:function ($$v) {_vm.$set(_vm.form, "uscc", $$v)},expression:"form.uscc"}})],1),_c('el-form-item',{attrs:{"label":"所属行业","prop":"industry","rules":[
          { required: true, message: '请选择所属行业', trigger: 'change' },
        ]}},[_c('q-industry-cascader',{attrs:{"schema":{ placeholder: '请选择所属行业' }},model:{value:(_vm.form.industry),callback:function ($$v) {_vm.$set(_vm.form, "industry", $$v)},expression:"form.industry"}})],1),_c('el-form-item',{attrs:{"label":"租户传真","prop":"fax","rules":[
          {
            pattern: _vm.regexs.phoneRegWithArea2,
            message: '请输入正确的传真',
            trigger: 'change',
          },
        ]}},[_c('el-input',{attrs:{"placeholder":"请输入租户传真"},model:{value:(_vm.form.fax),callback:function ($$v) {_vm.$set(_vm.form, "fax", $$v)},expression:"form.fax"}})],1),_c('el-form-item',{attrs:{"label":"租户地址","prop":"location","rules":[
          { required: true, message: '请选择租户地址', trigger: 'change' },
        ]}},[_c('q-location-cascader',{attrs:{"schema":{
            placeholder: '请选择租户地址',
            onClear: () => {
              _vm.form.address = null;
            },
          }},model:{value:(_vm.form.location),callback:function ($$v) {_vm.$set(_vm.form, "location", $$v)},expression:"form.location"}})],1),_c('el-form-item',{attrs:{"label":"","prop":"address","rules":[
          {
            required: _vm.$options.filters._location_has_value(_vm.form.location),
            message: '请输入完整的地址',
            trigger: 'change',
          },
        ]}},[_c('el-input',{attrs:{"maxlength":50,"disabled":_vm.$route.query.mode === 'view' ||
              !_vm.$options.filters._location_has_value(_vm.form.location),"type":"textarea","rows":4},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onSubmit}},[_vm._v("确定")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }