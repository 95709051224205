<template>
  <q-subject subject="修改密码">
    <div class="form-panel">
      <el-form ref="form" :model="form" label-width="120px">
        <div
          style="padding-left:120px;margin-bottom:40px;"
          v-if="!form.passwordChangeComplete"
        >
          首次登陆，需重新设置密码！
        </div>
        <el-form-item label="手机号码" prop="phone">
          <el-row>
            <el-col :span="7">
              {{ form.phone }}
            </el-col>
            <el-col :span="6">
              <el-button @click="sms()" :disabled="form.sendButtonDisabled">{{
                form.sendButtonText
              }}</el-button>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item
          label="验证码"
          prop="captcha"
          :rules="[
            { required: true, message: '验证码不能为空', trigger: 'change' },
          ]"
        >
          <el-input
            v-model="form.captcha"
            placeholder="请输入验证码"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="新密码"
          prop="newPwd"
          :rules="[
            { required: true, message: '新密码不能为空', trigger: 'change' },
            {
              pattern: regexs.pwd,
              message: '请输入8~15位字母和数字组成的密码',
              trigger: 'change',
            },
          ]"
        >
          <el-input
            v-model="form.newPwd"
            placeholder="请输入新密码"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item
          label="确认新密码"
          prop="reNewPwd"
          :rules="[
            {
              required: true,
              message: '确认新密码不能为空',
              trigger: 'change',
            },
            {
              validator: validateReNewPwd,
              trigger: 'change',
            },
          ]"
        >
          <el-input
            v-model="form.reNewPwd"
            placeholder="请再次输入新密码"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">确定</el-button>
          <el-button v-if="form.passwordChangeComplete" @click="$goHome()"
            >取消</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </q-subject>
</template>

<script>
import BizCode from "@/utils/BizCode";
import api from "@/api/customerUser";
import captchaApi from "@/api/captcha";
import { sleep } from "@/utils/index";
import { regexs } from "@/utils/validate";
import AuthClient from "@/utils/AuthClient";

export default {
  async mounted() {
    const result = await api.self();
    if (result.code === BizCode.OK) {
      const { data: self = {} } = result;
      const { phone, passwordChangeComplete } = self;
      this.form.phone = phone;
      this.form.passwordChangeComplete = passwordChangeComplete;
    }
  },
  data() {
    return {
      regexs: regexs,
      form: {
        passwordChangeComplete: true,
        phone: "",
        captcha: "",
        newPwd: "",
        reNewPwd: "",
        sendButtonDisabled: false,
        sendButtonText: "获取验证码",
      },
    };
  },
  methods: {
    validateReNewPwd(rule, value, callback) {
      if (value !== this.form.newPwd) {
        callback(new Error("两次输入密码不一致，请重新输入"));
      } else {
        callback();
      }
    },
    async sms() {
      try {
        const response = await captchaApi.sms({
          phone: this.form.phone,
        });
        //发送成功
        console.log(response, "response");
        //等待60秒
        let originText = this.form.sendButtonText;
        this.form.sendButtonDisabled = true;
        let count = 60;
        while (count > 0) {
          this.form.sendButtonText = `等待${count}秒`;
          count--;
          //停一秒
          await sleep(1000);
        }
        this.form.sendButtonText = originText;
        this.form.sendButtonDisabled = false;
      } catch (err) {
        //发送失败
        console.log(err, "发送失败");
      }
    },
    onSubmit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          //验证成功
          const result = await api.updatePwd({
            password: this.form.newPwd,
            code: this.form.captcha,
          });
          if (result.code === BizCode.OK) {
            //修改密码成功后
            this.$message.success("修改成功");
            const {to} = this.$route.query;
            if (to) {
              //判断是否有/
              const lastString = to.substr(to.length-1,1);
              window.location.href = `${to}${lastString === '/' ? '' : '/'}server/logout`
            } else {
              //退出
              AuthClient.getInstance().logout();
            }
          }
          return true;
        } else {
          //验证失败
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-panel {
  margin-top: 50px;
  width: 600px;
}
</style>
