<template>
  <el-select
    :clearable="schema.clearable|_nil(true)"
    :disabled="schema.disabled|_nil(false)"
    :loading="loading"
    :placeholder="schema.placeholder"
    :value="value"
    @input="onInput"
    @change="onSelectChange"
  >
    <el-option
      v-for="opt in options"
      :key="opt.value"
      :label="opt.label"
      :value="opt.value"
    >
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: "QSelect",
  props: {
    value: {
      required: true,
    },
    schema: {
      type: Object,
      required: true,
    },
  },
  async mounted() {
    //观察,当remoteOnce发生变化时,再来一次
    if (this.schema.remoteOnce) {
      this.loading = true;
      const result = await this.schema.remoteOnce();
      this.loading = false;
      this.options = [...result];
    }
  },
  data() {
    return {
      loading: false,
      options: this.schema.opts ?? [],
    };
  },
  methods: {
    onInput(val) {
      this.$emit("input", val);
    },
    onSelectChange(val) {
      if (this.schema.onSelectChange) {
        this.schema.onSelectChange(val);
      }
    },
  },
  watch: {
    "schema.opts": {
      immediate: true,
      handler(val, oldVal) {
        if (val !== oldVal) {
          this.options = [...val];
        }
      },
    },
  },
};
</script>
<style scoped></style>
