var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('bar',{attrs:{"applicationList":_vm.applicationList}}),_c('div',{staticClass:"x-layout body"},[_c('div',{staticClass:"x-aside left"},[_c('ul',{staticClass:"menu"},_vm._l((_vm.menuItems),function(menuItem,index){return _c('li',{key:`menuItems_${index}`,class:[
            'item',
            { active: _vm.overMenu === index },
            { selected: _vm.$route.params.category === menuItem.category },
          ],on:{"mouseover":function($event){return _vm.enterMenu(index)},"mouseout":function($event){return _vm.leaveMenu(index)},"click":function($event){return _vm.onMenuSelect(menuItem.category)}}},[_c('span',{staticClass:"subject"},[_vm._v(" "+_vm._s(menuItem.subject)+" ")]),_c('span',{staticClass:"num"},[_vm._v(" "+_vm._s(menuItem.num)+" ")])])}),0)]),_c('div',{staticClass:"x-content right"},[_c('ul',{staticClass:"cards"},_vm._l((_vm.projectCards),function(card,index){return _c('li',{key:`card_${index}`,class:['card', `__card_${index}`, { active: _vm.overCard === index }],on:{"mouseover":function($event){return _vm.enterCard(index)},"mouseout":function($event){return _vm.leaveCard(index)}}},[_c('div',{staticClass:"card-item",on:{"click":function($event){return _vm.onAppSelect(card)}}},[_c('img',{attrs:{"src":card.logo,"width":"320px","height":"390px"}}),(card.subscribed)?_c('span',{class:[
                'icon',
                'iconfont',
                'icon-wujiaoxing-',
                'star',
                card.leftDays > 0 ? 'yellow' : 'gray',
              ]}):_vm._e(),_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(card.name)+" ")])])])}),0)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }