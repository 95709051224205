import Vue from "vue";

//全局filters
import "@/utils/filters";

//Vuex
import store from "./store";

import "normalize.css";

import App from "./App.vue";
import router from "./router";

// eslint-disable-next-line no-unused-vars
import { enhanceRouter } from "./permission.js";

import "@/assets/style/icons/iconfont.css";
import "animate.css";

import ElementUI from "element-ui";
import "@/assets/style/theme/theme/index.css";

import "./assets/style/index.scss";

//导入后退键
Vue.prototype.$back = () => window.history.back();

//低代码平台组件引入
import QEntry from "@/components/QEntry";

// eslint-disable-next-line no-irregular-whitespace
Vue.use(ElementUI, { size: "small" });

Vue.config.productionTip = false;

//低代码平台组件引入
Vue.use(QEntry);

//为router增加权限判断
enhanceRouter(router);

//回首页
Vue.prototype.$goHome = () => {
  //console.log(router.currentRoute, "router.currentRoute");
  if (router.currentRoute.name !== "Home") {
    router.push({
      name: "Home",
    });
  }
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
