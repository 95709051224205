<template>
  <div>
    <el-form @submit.native.prevent  :inline="true" :model="formData" class="demo-form-inline">
      <el-form-item label="信息搜索">
        <el-input
          style="width:300px"
          @keyup.enter.native="onSubmit"
          v-model="formData.key"
          placeholder="搜索员工姓名或手机号码关键词"
        ></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <q-select
          @keyup.enter.native="onSubmit"
          :schema="{
            xType: 'q-select',
            placeholder: '全部',
            opts: [
              { label: '全部', value: null },
              { label: '已启用', value: false },
              { label: '已禁用', value: true },
            ],
          }"
          v-model="formData.disabled"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      formData: {
        key: "",
        disabled: null,
      },
    };
  },
  methods: {
    onSubmit() {
      //console.log("submit!");
      this.$emit("onSearch", this.formData);
    },
  },
  //还原query参数
  watch: {
    "$route.query": {
      handler(p) {
        // this.searchForm.page = parseInt(p.page || 1);
        // this.searchForm.key = p.key || '';
        // this.searchForm.classification = p.classification || '';
        // this.searchForm.status = p.status || '';
        // this.doSearchForm = { ...this.searchForm };
        // this.currentPage = parseInt(p.page ?? 1);
        // this.pageSize = parseInt(p.size ?? 5);
        console.log(p, "p...............");
        let queryParams = {};
        if (p?.queryParams !== null && p?.queryParams !== undefined) {
          queryParams = JSON.parse(window.decodeURI(p.queryParams));
        } else {
          queryParams = {};
        }

        console.log(queryParams, "p.queryParams......");

        const { key = "", disabled = null } = queryParams;
        this.formData = {
          key,
          disabled,
        };

        //this.setOrderParams(p.orderParams ?? {});
        //this.fetchData();
        //this.syncServer(); //获取数据
      },
      immediate: true, //立即执行一次需要
    },
  },
};
</script>
