import DelayLoadingService from "@/components/LoadingService/DelayLoadingService";
import { StatusCodes } from "http-status-codes";
//验证客户端

class AuthClient {
  //单例singleton
  static _instance;
  static getInstance() {
    if (AuthClient._instance === null || AuthClient._instance === undefined) {
      AuthClient._instance = new AuthClient();
    }
    return AuthClient._instance;
  }

  //检查认证状态
  async checkAuthState() {
    const result = await this.requestForCheck();
    if (result === StatusCodes.FORBIDDEN) {
      this.redirectForAuth();
      throw new Error("无权限");
    }
  }

  //登出
  logout() {
    window.location.href = "/server/logout";
    // const result = await fetch("/server/back_channel_logout");
    // console.log(result, "result");
  }

  //重定向授权
  redirectForAuth() {
    DelayLoadingService.getService().openLoading();
    const path = window.location.pathname + window.location.search;
    console.log(path, "/server/landing?to=" + path,"-------------path");
    window.location.href = "/server/landing?to=" + path;
  }

  //请求认证
  async requestForCheck() {
    try {
      const result = await fetch("/server/probe");
      console.log(result,'result');
      if (result.status === StatusCodes.FORBIDDEN) {
        console.log(window.location.hash);
        console.log(result.status, "result");
        return StatusCodes.FORBIDDEN;
      }
      //其他正常token并未失效
    } catch (err) {
      console.log(err);
    }
    return StatusCodes.OK;
  }
}
export default AuthClient;
