<template>
  <div class="x-layout body">
    <div class="subject-bar">
      {{ subject }}
    </div>
    <slot> </slot>
  </div>
</template>
<script>
export default {
  props: {
    subject: {
      type: String,
      default: () => "",
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  border: 1px solid #3e434a;
  border-radius: 4px;
  //margin-top: 32px;
  flex-shrink: 0; //禁止被压缩
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .subject-bar {
    //align-self: flex-start;
    height: 48px;
    line-height: 48px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.06);
    font-size: 14px;
    color: #c1c6cc;
    font-weight: 400;
    // background: #ffffff;
    // opacity: 0.06;
    //border-radius: 4px;
    box-sizing: border-box;
    padding-left: 20px;
  }
}
</style>
