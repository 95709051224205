<template>
  <q-subject subject="完善基本信息">
    <div class="form-panel">
      <el-form ref="form" :model="form" label-width="150px">
        <div
          style="padding-left:150px;margin-bottom:40px;"
          v-if="!form.customerInfoComplete"
        >
          首次登陆，需确认并完善企业信息！
        </div>

        <el-form-item
          label="公司名称"
          prop="name"
          :rules="[
            { required: true, message: '请输入公司名称', trigger: 'change' },
          ]"
        >
          <el-input v-model="form.name" placeholder="请输入公司名称"></el-input>
        </el-form-item>
        <el-form-item
          label="公司简称"
          prop="simpleName"
          :rules="[
            { required: true, message: '请输入公司简称', trigger: 'change' },
          ]"
        >
          <el-input
            v-model="form.simpleName"
            placeholder="请输入公司简称"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="负责人"
          prop="managerName"
          :rules="[
            { required: true, message: '请输入负责人', trigger: 'change' },
          ]"
        >
          <el-input
            v-model="form.managerName"
            placeholder="请输入负责人"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="负责人手机"
          prop="managerPhone"
          :rules="[
            {
              required: true,
              message: '请输入负责人手机',
              trigger: 'change',
            },
          ]"
        >
          <el-row>
            <el-col :span="7">
              {{ form.managerPhone }}
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item
          label="企业邮箱"
          prop="email"
          :rules="[
            {
              type: 'email',
              message: '请输入正确的邮箱地址',
              trigger: 'change',
            },
          ]"
        >
          <el-input
            v-model="form.email"
            placeholder="请输入企业邮箱"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="公司电话"
          prop="phone"
          :rules="[
            {
              pattern: regexs.phoneRegWithArea2,
              message: '请输入正确的电话',
              trigger: 'change',
            },
          ]"
        >
          <el-input
            v-model="form.phone"
            placeholder="请输入公司电话"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="社会统一信用代码"
          prop="uscc"
          :rules="[
            {
              required: true,
              message: '请输入社会统一信用代码',
              trigger: 'change',
            },
            {
              pattern: regexs.uscc,
              message: '请输入正确的社会统一信用代码',
              trigger: 'change',
            },
          ]"
        >
          <el-input
            v-model="form.uscc"
            placeholder="请输入社会统一信用代码"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="所属行业"
          prop="industry"
          :rules="[
            { required: true, message: '请选择所属行业', trigger: 'change' },
          ]"
        >
          <q-industry-cascader
            :schema="{ placeholder: '请选择所属行业' }"
            v-model="form.industry"
          ></q-industry-cascader>
        </el-form-item>
        <el-form-item
          label="租户传真"
          prop="fax"
          :rules="[
            {
              pattern: regexs.phoneRegWithArea2,
              message: '请输入正确的传真',
              trigger: 'change',
            },
          ]"
        >
          <el-input v-model="form.fax" placeholder="请输入租户传真"></el-input>
        </el-form-item>
        <el-form-item
          label="租户地址"
          prop="location"
          :rules="[
            { required: true, message: '请选择租户地址', trigger: 'change' },
          ]"
        >
          <q-location-cascader
            :schema="{
              placeholder: '请选择租户地址',
              onClear: () => {
                form.address = null;
              },
            }"
            v-model="form.location"
          ></q-location-cascader>
        </el-form-item>
        <el-form-item
          label=""
          prop="address"
          :rules="[
            {
              required: $options.filters._location_has_value(form.location),
              message: '请输入完整的地址',
              trigger: 'change',
            },
          ]"
        >
          <el-input
            :maxlength="50"
            :disabled="
              $route.query.mode === 'view' ||
                !$options.filters._location_has_value(form.location)
            "
            v-model="form.address"
            type="textarea"
            :rows="4"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit">确定</el-button>
          <!-- <el-button @click="dialogVisible = false">取消</el-button> -->
        </el-form-item>
      </el-form>
    </div>
  </q-subject>
</template>

<script>
import BizCode from "@/utils/BizCode";
import api from "@/api/customer";
// import captchaApi from "@/api/captcha";
// import { sleep } from "@/utils/index";
import { regexs } from "@/utils/validate";

export default {
  async mounted() {
    const result = await api.self();
    console.log(result, "result");
    if (result.code === BizCode.OK) {
      const {
        name,
        simpleName,
        managerName,
        managerPhone,
        email,
        phone,
        uscc,
        primaryIndustryId,
        secondaryIndustryId,
        minorIndustryId,
        fax,
        province,
        city,
        district,
        address,
      } = result.data;

      this.form = {
        name,
        simpleName,
        managerName,
        managerPhone,
        email,
        phone,
        uscc,
        industry: [primaryIndustryId, secondaryIndustryId, minorIndustryId],
        fax,
        location: [province, city, district],
        address,
      };
    }
  },
  data() {
    return {
      regexs: regexs,
      form: {
        customerInfoComplete: true,
        name: "",
        simpleName: "",
        managerName: "",
        managerPhone: "",
        email: "",
        phone: "",
        uscc: "",
        industry: [],
        fax: "",
        location: [],
        address: "",
      },
    };
  },
  methods: {
    onSubmit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          //验证成功
          const {
            name,
            simpleName,
            managerName,
            managerPhone,
            email,
            phone,
            uscc,
            industry = [],
            fax,
            location = [],
            address,
          } = this.form;
          const form = {
            name: name,
            simpleName: simpleName,
            managerName: managerName,
            managerPhone: managerPhone,
            email: email,
            phone: phone,
            uscc: uscc,
            primaryIndustryId: industry[0] ?? null,
            secondaryIndustryId: industry[1] ?? null,
            minorIndustryId: industry[2] ?? null,
            fax: fax,
            province: location[0] ?? null,
            city: location[1] ?? null,
            district: location[2] ?? null,
            address: address,
          };
          const result = await api.updateSelf(form);
          if (result.code === BizCode.OK) {
            //服务器成功返回后
            this.$message.success("修改成功");
            //跳转Home
            this.$router.push({
              name: "Home",
            });
          }
          return true;
        } else {
          //验证失败
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-panel {
  margin-top: 50px;
  width: 600px;
}
</style>
