import { render, staticRenderFns } from "./AuthorityGroupEdit.vue?vue&type=template&id=3f3cd520&scoped=true&"
import script from "./AuthorityGroupEdit.vue?vue&type=script&lang=js&"
export * from "./AuthorityGroupEdit.vue?vue&type=script&lang=js&"
import style0 from "./AuthorityGroupEdit.vue?vue&type=style&index=0&id=3f3cd520&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f3cd520",
  null
  
)

export default component.exports