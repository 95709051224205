<template>
  <q-subject subject="权限组管理">
    <div class="search-grid">
      <div class="search-grid-tool-bar">
        <el-button
          type="primary"
          @click="
            () => {
              $router.push({
                name: 'AuthorityGroupEdit',
                query: {
                  mode: 'add',
                },
              });
            }
          "
        >
          + 新增权限组
        </el-button>
      </div>
      <div>
        <authority-group-search-form @onSearch="onSearch" />
      </div>
      <q-grid-view
        ref="gridview"
        :schema="{ pagingEnable: true, getPageList: getPageList }"
      >
        <!-- <el-table-column prop="id" label="id" width="180"> </el-table-column> -->
        <el-table-column prop="name" label="权限组名称"></el-table-column>
        <el-table-column prop="appId" label="应用">
          <template slot-scope="scope">
            {{ scope.row | _path("application.name") }}
          </template>
        </el-table-column>
        <el-table-column prop="createdDate" label="添加时间">
          <template slot-scope="scope">
            {{ scope.row.createdDate | _format_date_time }}
          </template>
        </el-table-column>
        <el-table-column prop="resources" label="菜单">
          <template slot-scope="scope">
            {{
              $options.filters
                ._nil(scope.row.resources, [])
                .map((it) => `【${it.name}】`)
                .join("")
            }}
          </template>
        </el-table-column>
        <el-table-column align="right" label="操作">
          <!-- <template slot="header" slot-scope="scope">
        <el-input
          v-model="search"
          size="mini"
          placeholder="输入关键字搜索"/>
      </template> -->
          <template slot-scope="scope">
            <el-button
              :disabled="
                !(
                  $options.filters._path(
                    scope.row,
                    'application.subscribed',
                    false
                  ) &&
                  $options.filters._path(scope.row, 'application.leftDays', 0) >
                    0
                )
              "
              @click="handleEdit(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </q-grid-view>
    </div>
  </q-subject>
</template>

<script>
import AuthorityGroupSearchForm from "./AuthorityGroupSearchForm";
import api from "@/api/customerAuthorityGroup";
import BizCode from "@/utils/BizCode";
import _ from "lodash";

export default {
  components: {
    AuthorityGroupSearchForm,
  },
  async mounted() {},
  data() {
    return {
      api: api,
    };
  },

  methods: {
    //搜索
    onSearch(formData) {
      const grid = this.$refs["gridview"];
      grid.currentPage = 1;
      grid.setQueryParams(_.cloneDeep(formData));
      //grid.syncServer();
    },
    //分页获取数据
    getPageList(...p) {
      return api.getPageList(...p);
    },
    //编辑
    // eslint-disable-next-line no-unused-vars
    handleEdit(index, row) {
      this.$router.push({
        name: "AuthorityGroupEdit",
        query: {
          mode: "update",
          uid: row.groupUid,
        },
      });
    },
    //删除
    // eslint-disable-next-line no-unused-vars
    async handleDelete(index, row) {
      try {
        await this.$confirm("确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
        //请求服务器
        const result = await api.del(row.groupUid);
        if (result.code === BizCode.OK) {
          //若删除成功则刷新数据库
          const grid = this.$refs["gridview"];
          grid.syncServer();
        }
      } catch (err) {
        //取消按钮
        if (err !== "cancel") {
          throw err;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search-grid {
  align-self: stretch;
  margin: 0px 20px;
  .search-grid-tool-bar {
    height: 50px;
    line-height: 50px;
    text-align: right;
  }
}
</style>
