<template>
  <div>
    <q-subject subject="个人中心">
      <div class="form-panel">
        <el-form ref="form" :model="form" label-width="120px">
          <el-form-item
            label="姓名"
            prop="name"
            :rules="[
              { required: true, message: '姓名不能为空', trigger: 'change' },
            ]"
          >
            <el-input v-model="form.name" :maxlength="30"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-row>
              <el-col :span="5">
                {{ form.phone }}
              </el-col>
              <el-col :span="2">
                <i class="el-icon-warning-outline"></i>
              </el-col>
              <el-col :span="3">
                <el-button @click="dialogVisible = true" type="text"
                  >修改</el-button
                >
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item
            label="邮箱"
            prop="email"
            :rules="[
              {
                type: 'email',
                message: '请输入正确的邮箱地址',
                trigger: 'change',
              },
            ]"
          >
            <el-input v-model="form.email" :maxlength="30"></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="gender">
            <el-select v-model="form.gender" placeholder="请选择">
              <el-option :key="1" label="男" :value="1"> </el-option>
              <el-option :key="2" label="女" :value="2"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="通讯地址" prop="location">
            <q-location-cascader
              :schema="{
                placeholder: '请选择通讯地址',
                onClear: () => {
                  form.address = null;
                },
              }"
              v-model="form.location"
            ></q-location-cascader>
          </el-form-item>
          <el-form-item
            label=""
            prop="address"
            :rules="[
              {
                required: $options.filters._location_has_value(form.location),
                message: '请输入完整的地址',
                trigger: 'change',
              },
            ]"
          >
            <el-input
              :maxlength="50"
              :disabled="
                $route.query.mode === 'view' ||
                  !$options.filters._location_has_value(form.location)
              "
              v-model="form.address"
              type="textarea"
              :rows="4"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">确定</el-button>
            <el-button @click="$goHome()">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </q-subject>
    <el-dialog
      title="修改手机号码"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :visible.sync="dialogVisible"
      width="580px"
    >
      <el-form ref="phoneForm" :model="phoneForm" label-width="120px">
        <el-form-item label="原手机号码" prop="originPhone">
          <el-row>
            <el-col :span="7">
              {{ phoneForm.originPhone }}
            </el-col>
            <el-col :span="6">
              <el-button
                @click="sms()"
                :disabled="phoneForm.sendButtonDisabled"
                >{{ phoneForm.sendButtonText }}</el-button
              >
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item
          label="验证码"
          prop="captcha"
          :rules="[
            { required: true, message: '请输入验证码', trigger: 'change' },
          ]"
        >
          <el-input
            v-model="phoneForm.captcha"
            :maxlength="30"
            placeholder="请输入验证码"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="新手机号码"
          prop="newPhone"
          :rules="[
            { required: true, message: '请输入新手机号', trigger: 'change' },
            {
              pattern: regexs.mobile,
              message: '请输入正确的手机号',
              trigger: 'change',
            },
          ]"
        >
          <el-input
            v-model="phoneForm.newPhone"
            :maxlength="30"
            placeholder="请输入新手机号"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="changePhone">确定</el-button>
          <el-button @click="dialogVisible = false">取消</el-button>
        </el-form-item>
      </el-form>

      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import BizCode from "@/utils/BizCode";
import api from "@/api/customerUser";
import captchaApi from "@/api/captcha";
import { sleep } from "@/utils/index";
import { regexs } from "@/utils/validate";
import AuthClient from "@/utils/AuthClient";

export default {
  data() {
    return {
      regexs: regexs,
      dialogVisible: false,
      form: {
        name: "",
        phone: "",
        email: "",
        gender: 1,
        location: [],
        address: "",
      },
      phoneForm: {
        originPhone: "",
        captcha: "",
        newPhone: "",
        sendButtonDisabled: false,
        sendButtonText: "获取验证码",
      },
    };
  },
  async mounted() {
    const result = await api.self();
    if (result.code === BizCode.OK) {
      const { data: self = {} } = result;
      const {
        name,
        phone,
        email,
        gender,
        province,
        city,
        district,
        address,
      } = self;
      this.form.name = name;
      this.form.phone = phone;
      this.form.email = email;
      this.form.gender = gender;
      this.form.location = [province, city, district];
      this.form.address = address;
      this.phoneForm.originPhone = phone;
    }
  },
  methods: {
    async sms() {
      try {
        const response = await captchaApi.sms({
          phone: this.phoneForm.originPhone,
        });
        //发送成功
        console.log(response, "response");
        //等待60秒
        let originText = this.phoneForm.sendButtonText;
        this.phoneForm.sendButtonDisabled = true;
        let count = 60;
        while (count > 0) {
          this.phoneForm.sendButtonText = `等待${count}秒`;
          count--;
          //停一秒
          await sleep(1000);
        }
        this.phoneForm.sendButtonText = originText;
        this.phoneForm.sendButtonDisabled = false;
      } catch (err) {
        //发送失败
        console.log(err, "发送失败");
      }
    },
    changePhone() {
      this.$refs["phoneForm"].validate(async (valid) => {
        if (valid) {
          //验证成功
          const result = await api.updatePhone({
            phone: this.phoneForm.newPhone,
            code: this.phoneForm.captcha,
          });
          if (result.code === BizCode.OK) {
            //短信验证码检查成功更新手机号
            this.form.phone = this.phoneForm.newPhone;
            this.phoneForm.originPhone = this.phoneForm.newPhone;
            this.phoneForm.captcha = "";
            this.phoneForm.newPhone = "";
            // this.phoneForm.sendButtonDisabled=false;
            // this.phoneForm.sendButtonText="获取验证码";
            this.dialogVisible = false;
            //退出
            AuthClient.getInstance().logout();
          }
          return true;
        } else {
          //验证失败
          return false;
        }
      });
    },
    onSubmit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          //验证成功
          const {
            name,
            phone,
            email,
            gender,
            location = [],
            address,
          } = this.form;
          const form = {
            name: name,
            phone: phone,
            email: email,
            gender: gender,
            province: location[0] ?? null,
            city: location[1] ?? null,
            district: location[2] ?? null,
            address: address,
          };
          const result = await api.updateSelf(form);
          if (result.code === BizCode.OK) {
            //服务器成功返回后
            this.$message.success("修改成功");
            //跳转Home
            this.$router.push({
              name: "Home",
            });
          }
          return true;
        } else {
          //验证失败
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-panel {
  margin-top: 50px;
  width: 600px;
}
</style>
