<template>
  <q-subject :subject="$route.query.mode === 'add' ? '新增员工' : '修改员工'">
    <div class="form-panel">
      <el-form
        ref="form"
        :model="form"
        label-width="150px"
        :disabled="disabled"
      >
        <el-form-item
          label="员工姓名"
          prop="name"
          :rules="[
            {
              required: true,
              message: '员工姓名不能为空',
              trigger: 'change',
            },
          ]"
        >
          <el-input
            v-model="form.name"
            :maxlength="30"
            :placeholder="disabled ? '' : '请输入员工姓名'"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="所在部门"
          prop="departmentId"
          :rules="[
            { required: true, message: '请选择所在部门', trigger: 'change' },
          ]"
        >
          <q-select
            :schema="{
              disabled: true,
              xType: 'q-select',
              placeholder: '请选择所在部门',
              clearable: false,
              opts: deptOpts,
              onSelectChange: onSelectChange,
            }"
            v-model="form.departmentId"
          />
        </el-form-item>
        <el-form-item
          label="手机号"
          prop="phone"
          :rules="[
            { required: true, message: '手机号不能为空', trigger: 'change' },
            {
              pattern: regexs.mobile,
              message: '请输入正确的手机号',
              trigger: 'change',
            },
          ]"
        >
          <el-input
            v-model="form.phone"
            :maxlength="30"
            :placeholder="disabled ? '' : '请输入手机号'"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-show="false"
          label="是否为管理员"
          prop="departmentAdmin"
        >
          <el-radio-group
            :disabled="departmentAdminDisabled"
            v-model="form.departmentAdmin"
          >
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label=" 是否为演示账号"
          prop="showMode"
          :rules="[
            {
              required: true,
              message: '请选择是否为演示账号',
              trigger: 'change',
            },
          ]"
        >
          <el-radio-group v-model="form.showMode">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="邮箱"
          prop="email"
          :rules="[
            {
              type: 'email',
              message: '请输入正确的邮箱地址',
              trigger: 'change',
            },
          ]"
        >
          <el-input v-model="form.email"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="gender">
          <q-select
            :schema="{
              xType: 'q-select',
              placeholder: disabled ? '' : '请选择性别',
              opts: [
                {
                  label: '男',
                  value: 1,
                },
                {
                  label: '女',
                  value: 2,
                },
              ],
            }"
            v-model="form.gender"
          />
        </el-form-item>

        <el-form-item label="通讯地址" prop="location">
          <q-location-cascader
            :schema="{
              placeholder: disabled ? '' : '请选择通讯地址',
              onClear: () => {
                form.address = null;
              },
            }"
            v-model="form.location"
          ></q-location-cascader>
        </el-form-item>
        <el-form-item
          label=""
          prop="address"
          :rules="[
            {
              required: $options.filters._location_has_value(form.location),
              message: '请输入完整的地址',
              trigger: 'change',
            },
          ]"
        >
          <el-input
            :maxlength="50"
            :disabled="
              $route.query.mode === 'view' ||
                !$options.filters._location_has_value(form.location)
            "
            v-model="form.address"
            type="textarea"
            :rows="4"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="form-button">
        <el-button
          type="primary"
          @click="onSubmit"
          v-if="$route.query.mode !== 'view'"
          >确定</el-button
        >
        <el-button @click="$back()">返回</el-button>
      </div>
    </div>
  </q-subject>
</template>

<script>
import BizCode from "@/utils/BizCode";
import api from "@/api/customerUser";
// eslint-disable-next-line no-unused-vars
import { departmentTree, departmentList } from "@/api/departmentApi";
import { convertTree } from "@/utils/common";
import { regexs } from "@/utils/validate";
import QSubject from "../../../components/QSubject.vue";
import { LevelAll } from "@/utils/index";

export default {
  components: { QSubject },
  async mounted() {
    //获取部门
    if (this.$route?.query?.mode === "add") {
      await this.getDepartmentList();
      this.form.departmentId = this.getQueryDeptId();
      this.onSelectChange(this.form.departmentId); //手动触发下拉框改变事件
    } else if (
      this.$route?.query?.mode === "update" ||
      this.$route?.query?.mode === "view"
    ) {
      //修改或更新,需先获取需要的信息
      // eslint-disable-next-line no-unused-vars
      const [result, depList] = await Promise.all([
        api.detail(this.$route?.query?.muid),
        this.getDepartmentList(),
      ]);
      console.log(result, "result");
      if (result.code === BizCode.OK) {
        const { data } = result;
        const {
          name,
          department,
          phone,
          //departmentAdmin,
          showMode,
          email,
          gender,
          province,
          city,
          district,
          address,
        } = data;

        this.form = {
          name,
          departmentId: department?.id,
          phone,
          //departmentAdmin: departmentAdmin ?? null, //有联动效果不设置默认值方便调试
          showMode: showMode ?? false, //默认选择否
          email,
          gender,
          location: [province, city, district],
          address,
        };

        //departmentId根据departmentId来设置判断其可用状态
        //setDepartmentAdminDisabledById默认需要再修改时触发一次可用设置事件来判断部门管理员选择按钮是否可用
        //因为需要通过level来判断
        this.setDepartmentAdminDisabledById(this.form.departmentId);
      }
    }
  },
  data() {
    return {
      regexs: regexs,
      deptOpts: [],
      treeList: [],
      departmentAdminDisabled:
        this.disabled || this.$route.query.mode === "add", //默认值根据查看模式不同不同
      form: {
        departmentId: null,
        name: "",
        phone: "",
        departmentAdmin: null, //有联动效果不设置默认值方便调试
        showMode: false, //默认选择否
        email: "",
        gender: null,
        location: [],
        address: "",
      },
    };
  },
  methods: {
    //找到对应的level
    findDeptLevel(id) {
      return this.treeList.find((t) => t.id === id)?.level ?? -1;
    },
    //根据level设置departmentAdmin
    setDepartmentAdminByDeptLevel(level) {
      if (level === 1) {
        this.form.departmentAdmin = false;
      } else if (level === 2) {
        this.form.departmentAdmin = true;
      } else {
        this.form.departmentAdmin = undefined;
      }
    },

    //根据depId设置departmentAdminDisabled
    setDepartmentAdminDisabledById(depId) {
      if (this.disabled || this.$route.query.mode === "add") {
        this.departmentAdminDisabled = true;
      } else {
        const level = this.findDeptLevel(depId);
        if (this.$route.query.mode === "update" && level === 1) {
          this.departmentAdminDisabled = true;
        } else {
          this.departmentAdminDisabled = false;
        }
      }
    },

    onSelectChange(val) {
      console.log("触发onSelectChange事件");
      const level = this.findDeptLevel(val);
      this.setDepartmentAdminByDeptLevel(level);
      this.setDepartmentAdminDisabledById(val); //根据depId来设置可用状态,每次修改部门需判断一次切换事件
    },

    //从查询字符串中获取部门Id
    getQueryDeptId() {
      return this.$route?.query?.dep !== undefined &&
        this.$route?.query?.dep !== null &&
        this.$route?.query?.mode === "add"
        ? parseInt(this.$route?.query?.dep)
        : null;
    },
    async getDepartmentList() {
      const result = await departmentTree();
      // const result = await departmentList();
      if (result.code === BizCode.OK) {
        const data = result.data;
        const tree = convertTree(data, (node) => {
          return {
            id: node.data.id,
            label: node.data.name,
            mcid: node.data.mcid,
            root: node.data.root,
            children: [],
          };
        });
        console.log(tree, "tree");
        //只取前两级(废弃)取所有级
        const list = LevelAll(tree);
        console.log(list, "list");
        this.treeList = [...list];
        this.deptOpts = list.map((m) => {
          return { label: m.label, value: m.id };
        });
      }
    },
    onSubmit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          //验证成功
          const {
            name,
            departmentId,
            phone,
            //departmentAdmin,
            showMode,
            email,
            gender,
            location,
            address,
          } = this.form;
          const form = {
            name,
            phone,
            //departmentAdmin,
            showMode,
            email,
            gender,
            province: location[0] ?? null,
            city: location[1] ?? null,
            district: location[2] ?? null,
            address: address,
          };
          let result = null;
          if (this.$route?.query?.mode === "add") {
            //新增
            result = await api.add(departmentId, form);
          } else if (this.$route?.query?.mode === "update") {
            //修改
            result = await api.update(
              this.$route?.query?.muid,
              departmentId,
              form
            );
          }

          if (result.code === BizCode.OK) {
            //服务器成功返回后
            console.log(result.code, "员工这里准备返回");
            this.$back();
          }

          return true;
        } else {
          //验证失败
          return false;
        }
      });
    },
  },
  computed: {
    disabled() {
      return this.$route.query.mode === "view";
    },
  },
};
</script>

<style lang="scss" scoped>
.form-panel {
  margin-top: 50px;
  width: 600px;
}
.form-button {
  width: 600px;
  text-align: center;
  height: 80px;
  line-height: 80px;
}
</style>
