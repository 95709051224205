import { request } from "@/utils/request";
import { loading } from "@/utils/index";

const BASE_PATH = "/customerAuthorityGroup";

//权限
class Api {
  //新增
  @loading()
  async add(form) {
    return request.post(`${BASE_PATH}`, form);
  }

  //修改
  @loading()
  async update(uid, form) {
    return request.put(`${BASE_PATH}/${uid}`, form);
  }

  /** 权限详情 */
  @loading({ delay: 0 })
  async detail(uid) {
    return request.get(`${BASE_PATH}/${uid}`);
  }

  /** 删除 */
  @loading()
  async del(uid) {
    return request.delete(`${BASE_PATH}/${uid}`);
  }

  /** 分页获取权限列表 */
  @loading({ delay: 0 })
  async getPageList(params) {
    return request.get(`${BASE_PATH}`, { params: params });
  }

  /**
   * 列举应用下所有权限
   */
  @loading({ delay: 0 })
  listResources(params) {
    return request.get(`${BASE_PATH}/listResources`, { params: params });
  }

  //所有权限列表
  @loading({ delay: 0 })
  async listAll() {
    return request.get(`${BASE_PATH}/listAll`);
  }


    //根据用户获取相应的权限列表
    @loading({ delay: 0 })
    async fitCurrentUser(muid) {
      return request.get(`${BASE_PATH}/fitCurrentUser?muid=${muid}`);
    }
}

export default new Api();
