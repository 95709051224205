<template>
  <div>
    <bar
      :selectedSubject="selectedSubject"
      :applicationList="applicationList"
    />
    <div class="x-layout body">
      <div class="x-aside left">
        <div class="left-card">
          <div class="machine">
            <img :src="machineImage" width="130px" class="machine-image" />
            <!-- <img src="@/assets/style/images/machinebg.png" width="233px" /> -->
          </div>
          <div class="aironline">
            <img :src="projectTitleImage" width="192px" />
          </div>
          <div
            :key="index"
            v-for="(version, index) in $options.filters._nil(
              application.releaseNews,
              []
            )"
            class="comment"
          >
            <div class="caption">
              <i class="point"></i>
              <span>{{ version.versionNumber }}</span>
            </div>
            <ul>
              <li>
                {{ version.content }}
              </li>
            </ul>
          </div>
          <div class="space"></div>
          <div class="buttons">
            <div>
              <el-button
                v-if="application.subscribed && application.leftDays > 0"
                class="bluebutton1"
                type="primary"
                @click="enterApplication()"
              >
                进入应用
              </el-button>
              <button
                v-else
                class="graybutton"
                style="cursor:not-allowed"
                type="primary"
              >
                进入应用
              </button>
              <!-- <button class="bluebutton" @click="enterApplication()">
                进入应用
              </button> -->
            </div>
            <div>
              <el-button
                v-if="!(application.subscribed && application.leftDays > 0)"
                class="bluebutton1"
                type="primary"
                @click="buyTime()"
              >
                购买时间
              </el-button>
              <button
                v-else
                class="graybutton"
                style="cursor:not-allowed"
                type="primary"
                @click="buyTime()"
              >
                购买时间
              </button>
            </div>
          </div>
          <div class="space"></div>
        </div>
        <div class="left-mask"></div>
      </div>
      <div
        class="x-content right"
        @mouseenter="growRight()"
        @mouseleave="shrinkRight()"
      >
        <slot> </slot>
      </div>
    </div>
  </div>
</template>

<script>
import Bar from "./Bar.vue";
import { gsap } from "gsap";
import { playLoop } from "@/utils/index";
// import api from "@/api/application";
// import BizCode from "@/utils/BizCode";
import { mapState } from "vuex";
import { JumpToApplication } from "@/utils/index";

export default {
  name: "Home",
  components: {
    Bar,
  },
  props: {
    machineImage: {
      type: String,
      required: true,
    },
    projectTitleImage: {
      type: String,
      required: true,
    },

    selectedSubject: {
      type: String,
      required: true,
    },
    application: {
      type: Object,
      required: true,
    },
    applicationList: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.life = true;
    playLoop(".machine-image", "bounce", this, 4000);
    //清理固定的为了保持文字不动而设置的绝对宽度
    window.onresize = () => {
      return (() => {
        console.log("resize");
        this.clearFixWidth();
      })();
    };

    //this.loadApplication(this.$route.params?.appId);
    // if ((this.$route.params?.appId ?? "") === "qdzx") {
    //   //掌上织机
    // } else {
    //   //读取应用
    //   this.loadApplication(this.$route.params?.appId);
    // }
  },
  destroyed() {
    this.life = false;
  },
  data() {
    return {
      duration: 0.3,
      //application: {},
    };
  },
  methods: {
    //读取应用程序相关信息
    // async loadApplication(appId) {
    //   const result = await api.detail(appId);
    //   if (result.code === BizCode.OK) {
    //     console.log(result.data, "data......");
    //     this.application = { ...result.data };
    //   }
    // },
    buyTime() {
      if (
        this.application.subscribed &&
        (this.application.leftDays > 0 || this.application.leftDays === -1) &&
        this.application.startDate !== null &&
        this.application.startDate !== undefined &&
        this.application.endDate !== null &&
        this.application.endDate !== undefined
      ) {
        this.$alert(
          `有效期:${this.$options.filters._format_date(
            this.application.startDate
          )}~${this.$options.filters._format_date(this.application.endDate)}`,
          { type: "info" }
        );
      } else {
        this.$alert("请联系管理员进行购买!", { type: "info" });
      }
    },
    //进入应用
    enterApplication() {
      //跳转
      JumpToApplication(this.application, this.company.managerPhone, this);
    },
    //设置固定宽度
    setFixWidth() {
      try {
        //固定宽度
        const projectContent = document.querySelector(".project-content");
        const projectContentFixWidth = window.getComputedStyle(
          projectContent,
          null
        ).width;
        projectContent.style.width = projectContentFixWidth;

        //固定宽度
        const itemContent = document.querySelector(".item-content");
        const itemContentFixWidth = window.getComputedStyle(itemContent, null)
          .width;
        const itemContentList = document.querySelectorAll(".item-content");
        for (let item_content of itemContentList) {
          item_content.style.width = itemContentFixWidth;
        }
      } finally {
        //解决有时页面未加载完毕伸缩会报错的问题
      }
    },
    //取消固定宽度
    clearFixWidth() {
      try {
        const projectContent = document.querySelector(".project-content");
        const itemContentList = document.querySelectorAll(".item-content");
        projectContent.style.removeProperty("width");
        for (let item_content of itemContentList) {
          item_content.style.removeProperty("width");
        }
      } finally {
        //解决有时页面未加载完毕伸缩会报错的问题
      }
    },

    growRight() {
      //设置固定宽度
      this.setFixWidth();

      gsap.to(".right", { duration: this.duration, marginLeft: "-55px" });
      gsap.to(".left-mask", {
        duration: this.duration,
        opacity: 1,
        display: "block",
      });

      //linear-gradient(-90deg, #101621, #0f1620, #0f141d);
      let arrColor = new Array(
        "linear-gradient(-90deg, #101621, #0f1620)",
        "linear-gradient(-90deg, #0f1620, #0f141d)"
      );

      gsap.to(".opactiycard", {
        duration: this.duration,
        backgroundImage: arrColor,
      });
    },
    shrinkRight() {
      console.log("mouseout");
      gsap.to(".right", { duration: this.duration, marginLeft: "12px" });
      gsap.to(".left-mask", {
        duration: this.duration,
        opacity: 0,
        display: "none",
      });
      gsap.to(".opactiycard", {
        duration: this.duration,
        backgroundImage: "none",
      });
      // window.setTimeout(() => {
      //   //收尾,取消固定宽度
      //   this.clearFixWidth();
      // }, this.duration * 1000); //delay取消width的设定
    },
  },
  computed: mapState({
    company: (state) => state.customer.self,
    self: (state) => state.customerUser.self,
  }),
};
</script>

<style lang="scss" scoped>
.left-mask {
  width: 260px;
  height: 100%;
  // height: calc(100% - 100px);
  // top: 50px;
  top: 0;
  bottom: 0;
  //box-shadow: 70px 25px 10px 80px rgba(15, 20, 28, 1);
  opacity: 0;
  position: absolute;
  display: none;
  background-image: linear-gradient(
    to right,
    rgba(15, 20, 28, 0),
    rgba(15, 20, 28, 0.2),
    rgba(15, 20, 28, 0.4),
    rgba(15, 20, 28, 0.6),
    rgba(15, 20, 28, 0.8),
    rgba(15, 20, 28, 1),
    rgba(15, 20, 28, 1),
    rgba(15, 20, 28, 1)
  );
}

.body {
  margin-top: 32px;
  flex-shrink: 0; //禁止被压缩
  display: flex;
  //display: block;
  .left {
    flex-shrink: 0; //禁止被压缩
    width: 260px;
    position: relative;
    z-index: 1;
  }
  .left .left-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @extend .deepcard;
    //border-radius: 12px;
    //border-style: solid;
    //border-width: 1px;
    //border-color: red;

    //text-align: center;
    //background-color: greenyellow;
    .machine {
      @extend .fullbackground;
      text-align: center;
      margin-top: 12px;
      width: 260px;
      min-height: 156px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      //width: 260px;
      flex-shrink: 0;
      background-image: url("~@/assets/style/images/machinebg.png");
      background-size: 233px 156px;
      .machine-image {
        animation-duration: 3s;
      }
      // img {
      //   animation: bounce; /* referring directly to the animation's @keyframe declaration */
      //   animation-duration: 2s; /* don't forget to set a duration! */
      //     --animate-repeat: 2;
      //   //animate-repeat: 2;
      // }
    }

    .aironline {
      flex-shrink: 0;
    }

    .comment {
      flex-shrink: 0;
      //text-align: left;
      margin-top: 30px;
      line-height: 24px;
      width: 180px;
      .caption {
        .point {
          //标题前面的蓝色点
          width: 2px;
          height: 2px;
          margin-right: 3px;
          display: inline-block;
          vertical-align: middle;
          background-color: rgba(0, 104, 255, 1);
        }
      }
      ul {
        li {
          word-break: break-all;
        }
      }
    }

    .space {
      flex-shrink: 0;
      flex-grow: 0;
      height: 80px;
    }

    .buttons {
      flex-shrink: 0;
      flex-grow: 1;
      height: 110px;
      button {
        margin-top: 16px;
      }
    }
  }

  .right {
    margin-left: 12px;
    flex: auto;
    //overflow: auto; //若溢出-则滚动条
    box-sizing: border-box;
    z-index: 2;
    //flex-shrink: 0;
    // background-color: green;
  }
}
</style>
