//import defaultSettings from '@/settings'

export default function getPageTitle (pageTitle, isEntry) {
  console.log(pageTitle)
  if (isEntry) {
    return `${pageTitle}`
  } else if (pageTitle) {
    // return `空压在线-${pageTitle}`
    return `管理中心`
  } else {
    // return `空压在线-${defaultSettings.title}`
    return `管理中心`
  }
}


