import { request } from "@/utils/request";
import { loading } from "@/utils/index";

const BASE_PATH = "/customer";

//客户
class Api {
  //获取客户自身的信息
  @loading({ delay: 300 })
  async self() {
    return request.get(`${BASE_PATH}/self`);
  }

  //修改客户自身的信息
  @loading()
  async updateSelf(form) {
    return request.put(`${BASE_PATH}/updateSelf`, form);
  }
}

export default new Api();
