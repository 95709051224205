import { request } from "@/utils/request";
import { loading } from "@/utils/index";

const BASE_PATH = "/captcha";

//验证码
class Api {
  //短信验证码
  @loading()
  async sms(params) {
    return request.get(`${BASE_PATH}/sms`, { params: params });
  }
}

export default new Api();
