var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bar2"},[_vm._m(0),(_vm.applicationList.length > 0)?_c('ul',{staticClass:"logos"},_vm._l((_vm.topLogos),function(logo,index){return _c('li',{key:`topLogo_${index}`,class:[
        'logo',
        'flex-center',
        { active: _vm.overProject === index },
        { selected: _vm.selectedSubject === logo.appId },
      ],attrs:{"title":logo.title},on:{"click":function($event){return _vm.enter(logo)},"mouseover":function($event){return _vm.enterProject(index)},"mouseout":function($event){return _vm.leaveProject(index)}}},[_c('img',{attrs:{"src":logo.logoSrc,"width":_vm._f("_nil")(logo.width,null),"height":_vm._f("_nil")(logo.height,null)}})])}),0):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"projects flex-center"},[_c('img',{attrs:{"src":require("@/assets/style/images/rect.svg"),"height":"14px"}}),_c('span',{staticClass:"allproject"},[_vm._v(" 所有项目 ")])])
}]

export { render, staticRenderFns }