const BizCode = {
  OK: 200, //正确
  NEED_CHANGE_PWD: 10201, //首次登陆需要修改密码
  NEED_CHANGE_COMPANY_INFO: 10202, //需要填补公司信息
  NOT_SUBSCRIBED: 10054, //您还没有订阅，暂无权限访问此页面！
  NOT_BEGIN: 10055, //您的订阅未到开始时间，暂无权限访问此页面！
  OVER_DUE: 10056, //您的订阅已过期，请续费后重新打开！
  TOKEN_INVALID: 1101//TOKEN无效
};

export default BizCode;
