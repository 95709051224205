<template>
  <div class="bar2">
    <div class="projects flex-center">
      <img src="@/assets/style/images/rect.svg" height="14px" />
      <span class="allproject">
        所有项目
      </span>
    </div>
    <ul class="logos" v-if="applicationList.length > 0">
      <li
        :key="`topLogo_${index}`"
        v-for="(logo, index) in topLogos"
        :title="logo.title"
        :class="[
          'logo',
          'flex-center',
          { active: overProject === index },
          { selected: selectedSubject === logo.appId },
        ]"
        @click="enter(logo)"
        @mouseover="enterProject(index)"
        @mouseout="leaveProject(index)"
      >
        <img
          :src="logo.logoSrc"
          :width="logo.width | _nil(null)"
          :height="logo.height | _nil(null)"
        />
      </li>
    </ul>
  </div>
</template>
<script>
import { gsap } from "gsap";
import { mapState } from "vuex";
import { JumpToApplication } from "@/utils/index";

export default {
  mounted() {
    //gsap.to(".logos .logo", { rotation: 360, duration: 1, stagger: 0.4 });
  },
  props: {
    //选中的树节点的Id
    selectedSubject: {
      type: String,
      default: () => "",
    },
    applicationList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      overProject: -1,
      topLogos: [
        {
          appId: "yunqi",
          title: "云栖工厂",
          logoSrc: require("@/assets/style/images/logos/logo3.svg"),
          width: "42px",
        },
        {
          appId: "air",
          title: "空压在线",
          logoSrc: require("@/assets/style/images/logos/logo2.png"),
          height: "32px",
        },
        {
          appId: "qdzx",
          title: "掌上织机",
          logoSrc: require("@/assets/style/images/logos/logo1.svg"),
          height: "32px",
        },
        {
          appId: "qyyt",
          title: "企业云图",
          logoSrc: require("@/assets/style/images/logos/logo4.png"),
          width: "35px",
        },
      ],
    };
  },
  methods: {
    enter(app) {
      console.log(app);
      const application = this.applicationList.find(
        (a) => a.appId === app.appId
      );
      if (application !== null && application !== undefined) {
        //console.log(card, "card");
        if (application.appId === "air") {
          //空压在线则详情页
          const address = `/Detail/${application.appId}`;
          if (this.$route.path !== address) {
            this.$router.push(address);
          }
        } else {
          //跳转到对应的应用
          JumpToApplication(application, this.company.managerPhone, this);
        }
      } else {
        this.$message({
          message: "暂未添加此应用,请联系管理员",
          type: "warning",
        });
      }
    },
    enterProject(index) {
      this.overProject = index;
    },
    // eslint-disable-next-line no-unused-vars
    leaveProject(index) {
      //console.log(index);
      this.overProject = -1;
    },
  },
  computed: mapState({
    company: (state) => state.customer.self,
    self: (state) => state.customerUser.self,
  }),
  watch: {
    applicationList: {
      // eslint-disable-next-line no-unused-vars
      handler(val) {
        console.log("test");
        console.timeLog();
        this.$nextTick(() => {
          gsap.to(".logos .logo", { rotation: 360, duration: 1, stagger: 0.4 });
        });
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
$bar2height: 56px;

.bar2 {
  height: $bar2height + 2px;
  //background-color: indigo;
  display: flex;
  .projects {
    //background-color: springgreen;
    background-color: rgba(255, 255, 255, 0.06);

    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 2px;
    border-bottom-style: solid;
    border-bottom-color: #0068ff;

    //opacity: 0.06;
    text-align: center;
    width: 120px;
    height: $bar2height;
    font-size: 14px;

    .allproject {
      margin-left: 10px;
    }
  }

  .logos {
    margin-left: 10px;
    //background-color: skyblue;
    background-color: rgba(255, 255, 255, 0.06);
    border-radius: 4px;
    flex: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .logo {
      margin-right: 12px;
      width: 56px;
      height: $bar2height;
      cursor: pointer;
    }

    .active {
      background-color: rgba(255, 255, 255, 0.08);
      border-bottom: 2px;
      border-bottom-style: solid;
      border-bottom-color: #0068ff;
    }
    .selected {
      @extend .active;
    }
  }
}
</style>
