<template>
  <q-subject subject="组织架构">
    <div style="display:flex;width:100%">
      <div style="padding:10px">
        <organization-tree
          :width="330"
          :selectedId="treeId"
          @update:selectedId="
            (val,level) =>
              $router.push({
                path: this.$route.path,
                query: { treeId: val, t1: new Date().getTime(),level: level},
              })
          "
        />
      </div>
      <div class="staff-panel">
        <staff-manage :selectedId="treeId" />
      </div>
    </div>
  </q-subject>
</template>

<script>
import OrganizationTree from "./components/OrganizationTree";
import StaffManage from "./StaffManage";

export default {
  components: {
    OrganizationTree,
    StaffManage,
  },
  async mounted() {},
  data() {
    return {};
  },

  methods: {},
  computed: {
    treeId() {
      return parseInt(this.$route.query.treeId ?? -1);
    },
  },
};
</script>

<style lang="scss" scoped>
.staff-panel {
  flex: auto;
  overflow-x: hidden; //最完美的解决 flex element table 无法自适应缩小的BUG问题
}
</style>
