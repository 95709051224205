import Vue from "vue";
import _ from "lodash";
import moment from "moment";

Vue.filter("_nil", (value, ret = "") => {
  return value ?? ret;
});

Vue.filter("_path", (value, path, ret = undefined) => {
  return _.get(value, path, ret) ?? ret;
});

Vue.filter("_format_date_time", (value, ret = "") => {
  return value === undefined || value === null
    ? ret
    : moment(value).format("yyyy-MM-DD HH:mm:ss");
});


Vue.filter("_format_date", (value, ret = "") => {
  return value === undefined || value === null
    ? ret
    : moment(value).format("yyyy-MM-DD");
});


Vue.filter("_location_has_value", (value) => {
  if (value === undefined || value === null) {
    return false;
  }
  if (value.length === 0) {
    return false;
  }
  if (
    (value[0] === null || value[0] === undefined || value[0] === "") &&
    (value[1] === null || value[1] === undefined || value[1] === "") &&
    (value[2] === null || value[2] === undefined || value[2] === "")
  ) {
    return false;
  }
  return true;
});
