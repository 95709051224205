<template>
  <el-checkbox-group
    :value="value | _nil([])"
    @input="
      (val) => {
        $emit('input', val);
      }
    "
  >
    <el-checkbox v-for="opt in options" :key="opt.value" :label="opt.value">
      {{ opt.label }}
    </el-checkbox>
  </el-checkbox-group>
</template>

<script>
export default {
  name: "QCheckBoxGroup",
  props: {
    value: {
      required: true,
    },
    schema: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      options: this.schema.opts ?? [],
    };
  },
  methods: {},
  watch: {
    "schema.opts": {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler(val, oldVal) {
        if (val !== oldVal) {
          this.options = [...val];
        }
      },
    },
  },
};
</script>
