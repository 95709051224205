import { request } from "@/utils/request";
import { loading } from "@/utils/index";

const BASE_PATH = "/application";

//应用
class Api {
  //获取所有应用
  @loading({ delay: 300 })
  async list(params) {
    return request.get(`${BASE_PATH}/list`, { params: params });
  }

  //appId获取应用详情
  @loading({ delay: 300 })
  async detail(appId) {
    return request.get(`${BASE_PATH}/${appId}`);
  }

    //获取logo
    @loading({ delay: 300 })
    async logo(appId) {
        return request.get(`/customer/getAppDisplayLogo?appId=${appId}`);
    }
}

export default new Api();
