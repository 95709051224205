// eslint-disable-next-line no-unused-vars
import AuthClient from "@/utils/AuthClient";
import applicationApi from "@/api/application";
import BizCode from "@/utils/BizCode";
import store from "./store";
import getPageTitle from "@/utils/get-page-title";

//检查修改密码和完善公司信息的情况,若未修改则跳转到相应页面
const checkForJump = async (to, router) => {
  let jumped = false;
  console.log(to, "to.............");
  //样板api每次都试探是不是需要登录和完善信息
  if (
    (to.path === "/changePwd" || to.path === "/completeBasicInfo") &&
    to?.query?.from !== null &&
    to?.query?.from !== undefined
  ) {
    //这里刚刚重定向判断重定向过来不需要再次做重定向判断
  } else {
    try {
      await applicationApi.list();
    } catch (error) {
      //判断异常信息
      if (
        to.path !== "/changePwd" &&
        error?.response?.data?.code === BizCode.NEED_CHANGE_PWD
      ) {
        //首次登陆,修改密码
        jumped = true;
        router.push({
          path: "/changePwd",
          query: { from: new Date().getTime() },
          replace: true,
        });
        // next({
        //   ...to,
        //   name: null,
        //   path: "/changePwd",
        //   query: { from: new Date().getTime() },
        //   replace: true,
        // });
      } else if (
        to.path !== "/completeBasicInfo" &&
        error?.response?.data?.code === BizCode.NEED_CHANGE_COMPANY_INFO
      ) {
        //判断异常信息
        //首次登陆,修改密码
        jumped = true;
        router.push({
          path: "/completeBasicInfo",
          query: { from: new Date().getTime() },
          replace: true,
        });
        // next({
        //   ...to,
        //   name: null,
        //   path: "/completeBasicInfo",
        //   query: { from: new Date().getTime()  },
        //   replace: true,
        // });
      }
    }
  }

  return jumped;
};

//增加router的功能
export const enhanceRouter = (router) => {
  // eslint-disable-next-line no-unused-vars
  router.beforeEach(async (to, from, next) => {

    document.title = getPageTitle(to.meta.title, to.meta.isEntry);

    try {
      //验证有无有效token若无有效token直接跳转登录
      await AuthClient.getInstance().checkAuthState();

      //是否已经跳转过
      const jumped = await checkForJump(to, router);

      //建议每次导航都重新获取一下self数据,
      //这样单个接口不会造成更大压力,却能减少很多数据同步问题的处理
      
      //公司信息
      await store.dispatch("customer/self");
      //个人信息
      await store.dispatch("customerUser/self");


      // //只获取一次全局用户信息
      // if (!store.getters.self.done) {
      //   await store.dispatch("customerUser/self");
      //   console.log(store.getters.self, "全局用户信息");
      // }

      //若未跳转则调用next继续跳转当前页面
      if (!jumped) {
        next();
      }
    } catch (err) {
      console.log(err, "这里是无权限");
    }
  });
};
