var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"organization_tree",style:({ width: `${_vm.width}px` })},[_c('el-tree',{ref:"tree",attrs:{"current-node-key":_vm.selectedId,"data":_vm.treeList,"node-key":"id","default-expand-all":"","highlight-current":true,"expand-on-click-node":false},on:{"node-click":_vm.handleDepClick},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return _c('span',{class:[
        'dep-tree-node',
        node.level === 1 ? 'root-node' : 'common-node', //根节点还是普通节点的class
        { active: _vm.selectedId === data.id },
        { over: _vm.overTreeNode === data.id },
      ],on:{"mouseenter":function($event){_vm.overTreeNode = data.id},"mouseleave":function($event){_vm.overTreeNode = -1}}},[_c('span',{staticClass:"node-label",style:({
          width: `${_vm.computeLabelWidth(node)}px`,
        }),attrs:{"title":node.label}},[_vm._v(_vm._s(node.label))]),_c('span',{staticClass:"space"}),_c('span',{staticClass:"node-operation",style:({ display: [_vm.overTreeNode === data.id ? '' : 'none'] })},[(node.level === 1||_vm.self.admin)?_c('i',{staticClass:"el-icon-plus",on:{"click":function($event){$event.stopPropagation();return (() => _vm.edit(data, node, 'add')).apply(null, arguments)}}}):_vm._e(),(node.level === 2||(_vm.self.admin&&node.level !== 1))?_c('i',{staticClass:"el-icon-edit",on:{"click":function($event){$event.stopPropagation();return (() => _vm.edit(data, node, 'edit')).apply(null, arguments)}}}):_vm._e(),(node.level === 2||(_vm.self.admin&&node.level !== 1))?_c('i',{staticClass:"el-icon-delete",on:{"click":function($event){$event.stopPropagation();return (() => _vm.remove(data, node, 'remove')).apply(null, arguments)}}}):_vm._e()])])}}])}),_c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.departmentVisible,"width":"580px","before-close":_vm.closeAndReset},on:{"update:visible":function($event){_vm.departmentVisible=$event}}},[_c('div',{staticClass:"dialog_form"},[_c('el-form',{ref:"depForm",staticClass:"dep-form",attrs:{"model":_vm.depForm,"rules":_vm.rules,"label-width":"100px"}},[_c('el-form-item',{attrs:{"prop":"name","label":"部门名称"}},[_c('el-input',{attrs:{"maxlength":30},model:{value:(_vm.depForm.name),callback:function ($$v) {_vm.$set(_vm.depForm, "name", $$v)},expression:"depForm.name"}})],1),_c('el-form-item',{attrs:{"label":"上级部门","prop":"parentId"}},[_c('q-select',{attrs:{"schema":{
              xType: 'q-select',
              placeholder: '请选择上级部门',
              disabled:true,
              opts: _vm.depOpts,
            }},model:{value:(_vm.depForm.parentId),callback:function ($$v) {_vm.$set(_vm.depForm, "parentId", $$v)},expression:"depForm.parentId"}})],1),_c('el-form-item',{attrs:{"label":"部门logo","prop":"logo"}},[_c('el-upload',{class:{hide:_vm.hideUpload},attrs:{"action":"/api/file/upload","list-type":"picture-card","file-list":_vm.imagelist,"limit":1,"on-change":_vm.imgChange,"on-success":_vm.handleAvatarSuccess,"before-upload":_vm.beforeAvatarUpload,"on-remove":_vm.handleRemove}},[_c('i',{staticClass:"el-icon-plus"})])],1),(_vm.hideUpload)?_c('el-form-item',{attrs:{"label":"有效期","prop":"termOfValidity"}},[_c('el-date-picker',{attrs:{"type":"date","placeholder":"选择有效期","picker-options":_vm.pickerOptions},model:{value:(_vm.depForm.termOfValidity),callback:function ($$v) {_vm.$set(_vm.depForm, "termOfValidity", $$v)},expression:"depForm.termOfValidity"}})],1):_vm._e()],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.handelSubmit()}}},[_vm._v("确 定")]),_c('el-button',{on:{"click":function($event){return _vm.closeAndReset()}}},[_vm._v("取 消")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }