import DelayLoadingService from "@/components/LoadingService/DelayLoadingService";

//取前两集
// eslint-disable-next-line no-unused-vars
export const Level2 = (root) => {
  let nodes = [];

  const level1 = root;
  nodes.push({ ...level1, level: 1 });

  const level2 = level1.children ?? [];
  for (let child of level2) {
    nodes.push({ ...child, level: 2 });
  }

  return [...nodes];
};


//取所有级别
// eslint-disable-next-line no-unused-vars
export const LevelAll = (root) => {
  //let currentLevel=1;
  let nodes = [];
  const visit=(node,level)=>{
    nodes.push({...node,level:level});
    if(node?.children?.length){
      for (let child of node.children) {
        visit(child,  level+1 );
      }
    }
  }
  visit(root,1);
  return [...nodes];
};


//跳转到对应的应用的逻辑
export const JumpToApplication = (application, managerPhone, _self) => {
  //点击
  if (application.subscribed) {
    //已订阅
    if (application.leftDays > 0) {
      //且有效
      const linkUrl = application?.linkUrl;
      if (linkUrl !== undefined && linkUrl !== null) {
        if (linkUrl.includes("https://") || linkUrl.includes("http://")) {
          if (application.appId === "qdzx") { 
            // window.open(linkUrl.replace("{phone}", managerPhone));

            //掌上织机
            window.open('http://qd-zx.cn');
          }else if(application.appId === "qyyt"){
            //企业云图
            window.open('http://121.89.200.52:30195/view/2');
          }else {
            window.open(linkUrl);
          }
        } else {
          //非外部链接
          _self.$router.push(linkUrl);
        }
      } else {
        //链接为null
        _self.$message({
          message: "链接为null",
          type: "warning",
        });
      }
    } else if (application.leftDays === -1) {
      //未开始
      _self.$message({
        message: "未开始",
        type: "warning",
      });
    } else if (application.leftDays === 0) {
      //已过期
      _self.$message({
        message: "已过期",
        type: "warning",
      });
    }
  } else {
    //未订阅
    _self.$message({
      message: "未订阅",
      type: "warning",
    });
  }
};

//循环播放函数
export const playLoop = async (
  target,
  animation,
  component,
  duration = 2000
) => {
  while (component?.life ?? false) {
    await animateCSS(target, animation);
    await sleep(duration);
  }
};

// eslint-disable-next-line no-unused-vars
export const animateCSS = (element, animation, prefix = "animate__") =>
  // We create a Promise and return it
  // eslint-disable-next-line no-unused-vars
  new Promise((resolve, reject) => {
    const animationName = `${prefix}${animation}`;
    const node = document.querySelector(element);

    node.classList.add(`${prefix}animated`, animationName);

    // When the animation ends, we clean the classes and resolve the Promise
    function handleAnimationEnd(event) {
      event.stopPropagation();
      node.classList.remove(`${prefix}animated`, animationName);
      resolve("Animation ended");
    }

    node.addEventListener("animationend", handleAnimationEnd, { once: true });
  });

export function isPromise(obj) {
  return (
    !!obj &&
    (typeof obj === "object" || typeof obj === "function") &&
    typeof obj.then === "function"
  );
}

//休眠方法
export const sleep = async (second) => {
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    window.setTimeout(() => {
      resolve();
    }, second);
  });
};

//根据json中配置的id找到对应的组件
export const findComponentById = (sid, root) => {
  let result = null;
  const visit = (id, node) => {
    for (let sub of node.$children) {
      if (sub.schema?.id === id) {
        result = sub;
      }
      visit(id, sub);
    }
    return result;
  };
  visit(sid, root);
  return result;
};

// eslint-disable-next-line no-unused-vars
export function loading(opts = { delay: 0 }) {
  // eslint-disable-next-line no-unused-vars
  return function(target, propertyKey, descriptor) {
    let originFn = descriptor.value;
    descriptor.value = function(...p) {
      //console.log(`Calling ${propertyKey} with`, p);
      DelayLoadingService.getService().openLoading();
      try {
        let fnResult = originFn.apply(this, p);
        if (isPromise(fnResult)) {
          return fnResult
            .then((ret) => {
              if (opts.delay === 0) {
                DelayLoadingService.getService().closeLoadingImmediate();
              } else {
                DelayLoadingService.getService().closeLoading(opts.delay);
              }
              return ret;
            })
            .catch((err) => {
              DelayLoadingService.getService().closeLoadingImmediate();
              //此处抛给promise链而不是本函数自身外侧的catch方法,并且本函数外部的catch在关闭loading后也做了抛出,
              //所以异常未被吃掉,特别注意
              return Promise.reject(err); //或 throw err;
            });
        } else {
          DelayLoadingService.getService().closeLoadingImmediate();
          return fnResult;
        }
      } catch (err) {
        DelayLoadingService.getService().closeLoadingImmediate();
        throw err;
      }
    };
    return descriptor;
  };
}
