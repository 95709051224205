<template>
  <el-dropdown @command="handleCommand">
    <span class="el-dropdown-link">
      {{ self.name }}<i class="el-icon-arrow-down el-icon--right"></i>
    </span>
    <el-dropdown-menu slot="dropdown" v-if="self.admin">
      <el-dropdown-item command="/companyInfo">个人中心</el-dropdown-item>
      <el-dropdown-item command="/orgList">组织架构</el-dropdown-item>
      <el-dropdown-item command="/authorityGroup">权限组管理</el-dropdown-item>
      <el-dropdown-item command="/changePwd">修改密码</el-dropdown-item>
      <el-dropdown-item command="/logout">退出</el-dropdown-item>
    </el-dropdown-menu>
    <el-dropdown-menu slot="dropdown" v-else-if="self.departmentAdmin">
      <el-dropdown-item command="/personalCenter">个人中心</el-dropdown-item>
      <el-dropdown-item command="/orgList">组织架构</el-dropdown-item>
      <el-dropdown-item command="/authorityGroup">权限组管理</el-dropdown-item>
      <el-dropdown-item command="/changePwd">修改密码</el-dropdown-item>
      <el-dropdown-item command="/logout">退出</el-dropdown-item>
    </el-dropdown-menu>
    <el-dropdown-menu slot="dropdown" v-else>
      <el-dropdown-item command="/personalCenter">个人中心</el-dropdown-item>
      <el-dropdown-item command="/changePwd">修改密码</el-dropdown-item>
      <el-dropdown-item command="/logout">退出</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>
<script>
import { mapState } from "vuex";
import AuthClient from "@/utils/AuthClient";
export default {
  methods: {
    async handleCommand(command) {
      if ("/logout" === command) {
        //退出
        try {
          await this.$confirm("确认退出吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          });
          //退出
          AuthClient.getInstance().logout();
        } catch (err) {
          //取消按钮
          if (err !== "cancel") {
            throw err;
          }
        }
      } else if (this.$route.path !== command) {
        this.$router.push({
          path: command,
        });
      }
    },
  },

  computed: mapState({
    self: (state) => state.customerUser.self,
  }),
};
</script>
