<template>
  <q-subject subject="">
    <div class="deny">
      <div>
        <img
          src="@/assets/style/images/deny.png"
          alt="deny"
          width="372"
          height="200"
        />
      </div>
      <div class="deny-description">
        {{ decode($route.query.description) | _nil("暂无权限！") }}
      </div>
    </div>
  </q-subject>
</template>

<script>
export default {
  async mounted() {},
  data() {
    return {};
  },
  methods: {
    decode(str) {
      return window.decodeURI(str);
    },
  },
};
</script>

<style lang="scss" scoped>
.deny {
  //display: flex;
  margin-top: 100px;
  margin-bottom: 100px;
}
.deny-description {
  text-align: center;
  color: #9599a0;
  font-size: 16px;
}
</style>
