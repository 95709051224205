var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-subject',{attrs:{"subject":"权限组管理"}},[_c('div',{staticClass:"search-grid"},[_c('div',{staticClass:"search-grid-tool-bar"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":() => {
            _vm.$router.push({
              name: 'AuthorityGroupEdit',
              query: {
                mode: 'add',
              },
            });
          }}},[_vm._v(" + 新增权限组 ")])],1),_c('div',[_c('authority-group-search-form',{on:{"onSearch":_vm.onSearch}})],1),_c('q-grid-view',{ref:"gridview",attrs:{"schema":{ pagingEnable: true, getPageList: _vm.getPageList }}},[_c('el-table-column',{attrs:{"prop":"name","label":"权限组名称"}}),_c('el-table-column',{attrs:{"prop":"appId","label":"应用"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("_path")(scope.row,"application.name"))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"createdDate","label":"添加时间"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("_format_date_time")(scope.row.createdDate))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"resources","label":"菜单"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.$options.filters ._nil(scope.row.resources, []) .map((it) => `【${it.name}】`) .join(""))+" ")]}}])}),_c('el-table-column',{attrs:{"align":"right","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"disabled":!(
                _vm.$options.filters._path(
                  scope.row,
                  'application.subscribed',
                  false
                ) &&
                _vm.$options.filters._path(scope.row, 'application.leftDays', 0) >
                  0
              )},on:{"click":function($event){return _vm.handleEdit(scope.$index, scope.row)}}},[_vm._v("编辑")]),_c('el-button',{on:{"click":function($event){return _vm.handleDelete(scope.$index, scope.row)}}},[_vm._v("删除")])]}}])})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }