import moment from "moment";
import { isNumber } from "lodash";

//求y轴的最大值和最小值
export function yAxisMinAndMax(min, max) {
  const interval = Math.abs(max - min) / 2;
  return {
    min: min - interval,
    max: max + interval,
  };
}

//保留小数点位数的方法
export function toFixed(value, precision = 2) {
  return isNumber(value) ? value.toFixed(precision) : value;
}

//  数据报表里面生成唯一的guid
export function guid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

/** @description 获取本地的省市区 */
export function getLocation() {
  const location = require("@/assets/json/Location.json");
  let data = formatLocation(location.districts[0].districts);
  return getTreeData(data);
}

// 递归判断列表，把最后的children设为undefined，解决最后一列空白问题
export function getTreeData(data) {
  data.forEach((r) => {
    if (r.children.length < 1) {
      r.children = undefined;
    } else {
      getTreeData(r.children);
    }
  });
  return data;
}

export function formatLocation(locationArr) {
  const arr = [];
  let obj = {};
  locationArr.forEach((item) => {
    const tmp = { ...item, children: [] };
    if (tmp.districts) {
      tmp.children = formatLocation(tmp.districts);
      obj = {
        value: tmp.adcode,
        label: tmp.name,
        children: tmp.children,
      };
    }
    arr.push(obj);
  });
  return arr;
}
/** 树形数据的整合 */
export function treeData(arr) {
  let array = [],
    obj = {};
  arr.forEach((el) => {
    const tmp = { children: [] };
    if (el.children) {
      tmp.children = treeData(el.children);
    }
    obj = {
      id: el.data.id,
      label: el.data.name,
      machines: el.data.machines ? el.data.machines : [],
      children: tmp.children,
    };
    array.push(obj);
  });
  return array;
}

//通用树的转换方法
export const convertTree = (root, cb) => {
  const vis = (node, parent, cb) => {
    let newNode = cb(node);
    parent.children.push(newNode);
    if (node.children?.length) {
      for (let child of node.children) {
        vis(child, newNode, cb);
      }
    }
  };
  let newRoot = cb(root);
  if (root.children?.length) {
    for (let child of root.children) {
      vis(child, newRoot, cb);
    }
  }

  return newRoot;
};

/** 巡检计划里面的巡检人员树形结构整合 */
export function treeUser(arr) {
  let array = [],
    obj = {};
  arr.forEach((el) => {
    const tmp = { children: [] };
    if (el.children) {
      tmp.children = treeUser(el.children);
    }
    obj = {
      id: el.data.id,
      value: el.data.mcid,
      label: el.data.name,
      children: tmp.children.concat(
        el.data ? dataCommon(el.data.simpleCustomerList) : []
      ),
      disabled: true,
      isLeaf: false,
    };
    array.push(obj);
  });
  return array;
}

/** 巡检计划的巡检设备树形结构的特殊处理 */
export function treeDataDeviceTest(arr) {
  console.log(arr);
  let array = [],
    obj = {};
  arr.forEach((el) => {
    const tmp = { children: [] };
    if (el.machines && typeof el.machines === "object") {
      tmp.children = el.machines.children
        ? treeDataDeviceTest(el.machines.children)
        : [];
      obj = {
        id: el.mcid,
        value: el.mcid,
        label: el.name,
        disabled: true,
        isLeaf: false,
        machines: el.machines ? el.machines : [],
        children: tmp.children.concat(
          dataCommon(el.machines.data ? el.machines.data.machines : [])
        ),
      };
    } else {
      tmp.children = el.children ? treeDataDeviceTest(el.children) : [];
      obj = {
        id: el.data.mcid,
        value: el.data.mcid,
        label: el.data.name,
        disabled: true,
        isLeaf: false,
        machines: el.machines ? el.machines : [],
        children: tmp.children.concat(
          dataCommon(el.data ? el.data.machines : [])
        ),
      };
    }
    array.push(obj);
  });
  return array;
}

/** 巡检计划里面的巡检设备树形结构整合 */
export function treeDevice(arr) {
  let array = [],
    obj = {};
  arr.forEach((el) => {
    const tmp = { children: [] };
    if (el.children) {
      tmp.children = treeDevice(el.children);
    }
    obj = {
      id: el.id,
      value: el.id,
      label: el.name,
      children: tmp.children,
      disabled: true,
    };
    array.push(obj);
  });
  return array;
}
/** 树形数据的整合带有设备的 */
export function treeDataDevice(arr) {
  let array = [],
    obj = {};
  arr.forEach((el) => {
    const tmp = { children: [] };
    if (el.children) {
      tmp.children = treeDataDevice(el.children);
    }
    obj = {
      id: el.data.id,
      name: el.data.name,
      machines: el.data.machines ? el.data.machines : [],
      children: el.data.machines.concat(tmp.children),
      //   children: tmp.children.concat(el.data.machines)
      //   children: tmp.children
    };
    array.push(obj);
  });
  return array;
}

export function dataCommon(arr) {
  var array = [];
  if (arr && arr.length > 0) {
    arr.forEach(function(item) {
      array.push({
        id: item.id,
        value: item.id,
        label: item.name,
        isLeaf: true,
      });
    });
  }

  return array;
}

export function number_chinese(num) {
  let changeNum = ["零", "一", "二", "三", "四", "五", "六", "日", "八", "九"]; //changeNum[0] = "零"
  let unit = ["", "十", "百", "千", "万"];
  num = parseInt(num);
  let getWan = (temp) => {
    let strArr = temp
      .toString()
      .split("")
      .reverse();
    let newNum = "";
    for (var i = 0; i < strArr.length; i++) {
      newNum =
        (i == 0 && strArr[i] == 0
          ? ""
          : i > 0 && strArr[i] == 0 && strArr[i - 1] == 0
          ? ""
          : changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i])) +
        newNum;
    }
    return newNum;
  };
  let overWan = Math.floor(num / 10000);
  let noWan = num % 10000;
  if (noWan.toString().length < 4) noWan = "0" + noWan;
  return overWan ? getWan(overWan) + "万" + getWan(noWan) : getWan(num);
}

export function formatDateTime(v) {
  if (v) {
    return moment(v).format("YYYY-MM-DD HH:mm:ss");
  } else {
    return "";
  }
}

//遍历改造树
export function visit(node, action, prop = "children", level = 0, index = 0) {
  //prop必须存在
  if (!prop) {
    throw new Error("prop必须指定");
  }
  //若action存在
  if (action && typeof action === "function") {
    action(node, level, index, prop);
  }
  //子元素
  let children = node[prop];

  if (children && children.length > 0) {
    //层级+1
    level = level + 1;
    //序号再置为1
    for (index = 0; index < children.length; index++) {
      visit(children[index], action, prop, level, index);
    }
  }
}

export function formatDateHours(v) {
  if (v) {
    return moment(v).format("YYYY-MM-DD HH:mm");
  } else {
    return "";
  }
}

//遍历整个TREE

/* visit(root, (curret, level, index, prop) => {


    curret.newOrder = [...curret.machines, ...curret.children];

})

console.log(root); */
