import Vue from "vue";
import VueRouter from "vue-router";
//import Home from "../views/Home.vue";
import List from "../views/List.vue";
import Deny from "../views/Deny.vue";
import Detail from "../views/Detail.vue";
import ChangePwd from "../views/customer/ChangePwd.vue";
import PersonalCenter from "../views/customer/PersonalCenter.vue";

import CompleteBasicInfo from "../views/admin/CompleteBasicInfo.vue";
import CompanyInfo from "../views/admin/CompanyInfo.vue";

import OrganizationList from "../views/admin/staff/OrganizationList.vue";

import AuthorityGroup from "../views/admin/AuthorityGroup.vue";
import AuthorityGroupEdit from "../views/admin/AuthorityGroupEdit.vue";

import StaffManageEdit from "../views/admin/staff/StaffManageEdit.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/deny",
    name: "Deny",
    component: Deny,
  },
  {
    path: "/changePwd",
    name: "ChangePwd",
    component: ChangePwd,
  },
  {
    path: "/personalCenter",
    name: "PersonalCenter",
    component: PersonalCenter,
  },
  {
    path: "/completeBasicInfo",
    name: "CompleteBasicInfo",
    component: CompleteBasicInfo,
  },
  {
    path: "/companyInfo",
    name: "CompanyInfo",
    component: CompanyInfo,
  },
  {
    path: "/orgList",
    name: "OrganizationList",
    component: OrganizationList,
  },
  {
    path: "/staffManageEdit",
    name: "StaffManageEdit",
    component: StaffManageEdit,
  },
  {
    path: "/authorityGroup",
    name: "AuthorityGroup",
    component: AuthorityGroup,
  },
  {
    path: "/authorityGroupEdit",
    name: "AuthorityGroupEdit",
    component: AuthorityGroupEdit,
  },
  {
    path: "/Detail/:appId",
    name: "Detail",
    component: Detail,
    meta: {
      title: "丹碧德工业互联网平台",
      isEntry: true,
    },
  },
  {
    path: "/:category?",
    name: "Home",
    exact: true,
    component: List,
    meta: {
      title: "丹碧德工业互联网平台",
      isEntry: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
