<template>
  <div>
    <bar :applicationList="applicationList" />
    <div class="x-layout body">
      <div class="x-aside left">
        <ul class="menu">
          <li
            :key="`menuItems_${index}`"
            v-for="(menuItem, index) in menuItems"
            :class="[
              'item',
              { active: overMenu === index },
              { selected: $route.params.category === menuItem.category },
            ]"
            @mouseover="enterMenu(index)"
            @mouseout="leaveMenu(index)"
            @click="onMenuSelect(menuItem.category)"
          >
            <span class="subject">
              {{ menuItem.subject }}
            </span>
            <span class="num">
              {{ menuItem.num }}
            </span>
          </li>
        </ul>
      </div>
      <div class="x-content right">
        <ul class="cards">
          <li
            :key="`card_${index}`"
            v-for="(card, index) in projectCards"
            :class="['card', `__card_${index}`, { active: overCard === index }]"
            @mouseover="enterCard(index)"
            @mouseout="leaveCard(index)"
          >
            <div class="card-item" @click="onAppSelect(card)">
              <img :src="card.logo" width="320px" height="390px" />
              <span
                v-if="card.subscribed"
                :class="[
                  'icon',
                  'iconfont',
                  'icon-wujiaoxing-',
                  'star',
                  card.leftDays > 0 ? 'yellow' : 'gray',
                ]"
              >
              </span>
              <span class="caption">
                {{ card.name }}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Bar from "./Bar.vue";
import BizCode from "@/utils/BizCode";
import api from "@/api/application";

//import { gsap, Linear } from "gsap";
import { mapState } from "vuex";
import {
  JumpToApplication,
  //animateCSS
} from "@/utils/index";

import FlipPlayer from "@/utils/FlipPlayer";

export default {
  name: "Home",
  components: {
    Bar,
  },
  async mounted() {
    // const result = await api.list({ subscribed: null });
    // if (result.code === BizCode.OK) {
    //   this.projectCards = result.data;
    // }
  },
  //观察参数
  watch: {
    "$route.params": {
      async handler(p) {
        //所有数据
        let result = await api.list({ subscribed: null });
        console.log(result,"result---");
        if (result.code === BizCode.OK) {
          //列表
          const list = result?.data ?? [];
          this.applicationList = [...list];
          //设置条数
          //所有
          // this.menuItems[0].num = list.length;
          //已订阅
          // this.menuItems[1].num = list.filter((m) => m.subscribed).length;


          //添加列表后的条数
          if(this.$store.state.customerUser.self?.phone === '15052277976'){
            this.menuItems[0].num = [...result?.data ?? [], ...this.othersProjectCards].length;
            this.menuItems[1].num = [...list.filter((m) => m.subscribed), ...this.othersProjectCards].length;
          }else{
            this.menuItems[0].num = list.length;
            this.menuItems[1].num = list.filter((m) => m.subscribed).length;
          }
          //设置列表
          if (
            p.category === undefined ||
            p.category === null ||
            p.category === ""
          ) {
            //全部
            // this.projectCards = list;


            //账号为 15052277976 加上额外的列表
            if(this.$store.state.customerUser.self?.phone === '15052277976'){
              this.projectCards = [...list, ...this.othersProjectCards];
            }else{
              this.projectCards = list;
            }
            
          } else if (p.category === "subscribed") {
            //获取相应category的列表
            // this.projectCards = list.filter((m) => m.subscribed);


            if(this.$store.state.customerUser.self?.phone === '15052277976'){
              this.projectCards = [...list.filter((m) => m.subscribed), ...this.othersProjectCards];
            }else{
              this.projectCards = list.filter((m) => m.subscribed);
            }
            
          }

          // this.$nextTick(() => {
          //   gsap.fromTo(
          //     ".cards .card",
          //     {
          //       opacity: 0,

          //       ease: Linear.ease,
          //     },
          //     {
          //       opacity: 1,
          //       duration: 5,
          //     }
          //   );
          // });

          this.$nextTick(() => {
            new FlipPlayer(".cards .card").flip(); //此处不需要享元模式
          });

        }
      },
      immediate: true, //立即执行一次需要
    },
  },
  data() {
    return {
      overMenu: -1,
      overCard: -1,
      menuItems: [
        { subject: "所有项目", num: "", category: undefined },
        { subject: "订阅项目", num: "", category: "subscribed" },
      ],
      applicationList: [],
      projectCards: [
        // {
        //   logo: require("@/assets/style/images/projects/1.png"),
        //   name: "云栖工厂",
        // },
        // {
        //   logo: require("@/assets/style/images/projects/2.png"),
        //   name: "纺织物联",
        // },
      ],
      othersProjectCards:[
        { 
          id: 6,
          name: "厂务环安卫",
          leftDays: 1000, //订阅到期时间
          linkUrl: "https://dashboard-apps-ews002.labs.wise-paas.com.cn/frame/%E5%8E%82%E5%8A%A1%E5%9B%AD%E5%8C%BA%E7%AE%A1%E7%90%86%E7%B3%BB%E7%BB%9F?orgId=1&language=zh-CN&theme=gray&selected=BA77B8A7",
          logo: "https://mac-prod.oss-cn-zhangjiakou.aliyuncs.com/upload/app-icon/%E5%8E%82%E5%8A%A1%E7%8E%AF%E5%AE%89%E5%8D%AB.png",
          subscribed: true //是否订阅
        },
        {
          id: 7,
          name: "布检单",
          leftDays: 1000,
          linkUrl: "https://bxb.semoun.com/Index/Login",
          logo: "https://mac-prod.oss-cn-zhangjiakou.aliyuncs.com/upload/app-icon/%E5%B8%83%E6%A3%80%E5%8D%95.png",
          subscribed: true
        },
        {
          id: 8,
          name: "布小帮",
          leftDays: 1000,
          linkUrl: "https://cloud.semoun.com",
          logo: "https://mac-prod.oss-cn-zhangjiakou.aliyuncs.com/upload/app-icon/%E5%B8%83%E5%B0%8F%E5%B8%AE.png",
          subscribed: true
        },
        {
          id: 9,
          name: "加弹机监测",
          leftDays: 1000,
          linkUrl: "",
          logo: "https://mac-prod.oss-cn-zhangjiakou.aliyuncs.com/upload/app-icon/%E5%8A%A0%E5%BC%B9%E6%9C%BA.png",
          subscribed: true
        },
        {
          id: 10,
          name: "电能平台",
          leftDays: 1000,
          linkUrl: "http://e.smartwuxi.com/login",
          logo: "https://mac-prod.oss-cn-zhangjiakou.aliyuncs.com/upload/app-icon/%E7%94%B5%E8%83%BD%E5%B9%B3%E5%8F%B0.png",
          subscribed: true
        },
        {
          id: 11,
          name: "机床",
          leftDays: 1000,
          linkUrl: "https://elmp.y-geng.com.cn:8081/",
          logo: "https://mac-prod.oss-cn-zhangjiakou.aliyuncs.com/upload/app-icon/%E6%9C%BA%E5%BA%8A.png",
          subscribed: true
        },
        {
          id: 12,
          name: "爱洗衣",
          leftDays: 1000,
          linkUrl: "https://www.joyinlaundry.com/#/login",
          logo: "https://mac-prod.oss-cn-zhangjiakou.aliyuncs.com/upload/app-icon/%E7%88%B1%E6%B4%97%E8%A1%A3.png",
          subscribed: true
        },
        {
          id: 13,
          name: "智能打卷",
          leftDays: 1000,
          linkUrl: "http://hongmin1.jimite.top/zhibu/admins",
          logo: "https://mac-prod.oss-cn-zhangjiakou.aliyuncs.com/upload/app-icon/%E6%99%BA%E8%83%BD%E6%89%93%E5%8D%B7.png",
          subscribed: true
        },
        {
          id: 14,
          name: "AI",
          leftDays: 1000,
          linkUrl: "http://chat.esalotto.cn/",
          logo: "https://mac-prod.oss-cn-zhangjiakou.aliyuncs.com/upload/app-icon/ai.png",
          subscribed: true
        },
        {
          id: 15,
          name: "简道云（ERP)",
          leftDays: 1000,
          linkUrl: "https://www.jiandaoyun.com/signin",
          logo: "https://mac-prod.oss-cn-zhangjiakou.aliyuncs.com/upload/app-icon/%E7%AE%80%E9%81%93%E4%BA%91.png",
          subscribed: true
        },
        {
          id: 16,
          name: "空压在线（厂商定制版）",
          leftDays: 1000,
          linkUrl: "",
          logo: "https://mac-prod.oss-cn-zhangjiakou.aliyuncs.com/upload/app-icon/%E7%A9%BA%E5%8E%8B%E5%9C%A8%E7%BA%BF%E5%8E%82%E5%95%86%E5%AE%9A%E5%88%B6.png",
          subscribed: true
        },
        {
          id: 17,
          name: "金蝶星空云",
          leftDays: 1000,
          linkUrl: "",
          logo: "https://mac-prod.oss-cn-zhangjiakou.aliyuncs.com/upload/app-icon/%E9%87%91%E7%A2%9F%E6%98%9F%E7%A9%BA%E4%BA%91.png",
          subscribed: true
        },
        {
          id: 18,
          name: "物联网卡管理系统",
          leftDays: 1000,
          linkUrl: "https://iotcard.hodo170.com/iotcard/user/login?redirect=%2F",
          logo: "https://mac-prod.oss-cn-zhangjiakou.aliyuncs.com/upload/app-icon/%E7%BD%91%E8%81%94%E7%BD%91%E5%8D%A1%E7%AE%A1%E7%90%86%E7%B3%BB%E7%BB%9F.png",
          subscribed: true
        },
        {
          id: 19,
          name: "CNC",
          leftDays: 1000,
          linkUrl: "https://cloud.siger-data.com/#/login",
          logo: "https://mac-prod.oss-cn-zhangjiakou.aliyuncs.com/upload/app-icon/cnc.png",
          subscribed: true
        },
        {
          id: 20,
          name: "注塑机平台",
          leftDays: 1000,
          linkUrl: "https://cloud.123321yun.com/dataview/#/home?p=347",
          logo: "https://mac-prod.oss-cn-zhangjiakou.aliyuncs.com/upload/app-icon/%E6%B3%A8%E5%A1%91%E6%9C%BA.png",
          subscribed: true
        },
        {
          id: 21,
          name: "倍捻",
          leftDays: 1000,
          linkUrl: "http://sd6.ty.xudounet.com/",
          logo: "https://mac-prod.oss-cn-zhangjiakou.aliyuncs.com/upload/app-icon/%E5%80%8D%E6%8D%BB.png",
          subscribed: true
        }
      ],
    };
  },
  methods: {
    onMenuSelect(category) {
      if (this.$route.params.category !== category) {
        this.$router.push({
          name: "Home",
          params: {
            category: category,
          },
        });
      }
    },
    enterMenu(index) {
      this.overMenu = index;
    },
    // eslint-disable-next-line no-unused-vars
    leaveMenu(index) {
      //console.log(index);
      this.overMenu = -1;
    },
    async enterCard(index) {
      //this.overCard = index;
      this.overCard = index;
      const element = `.cards .__card_${index}`;
      //console.log("a");
      //享元模式防抖
      //console.log(filpPlayer.flyweight(element), "sdafsf");
      FlipPlayer.flyweight(element).debounceFlip();

      // await gsap.to(element, {
      //   transform: "perspective(400px) rotate3d(1, 0, 0, 10deg)",
      //   opacity: 1,
      //   duration: 0.2,
      // });

      // await gsap.to(element, {
      //   transform: "perspective(400px) rotate3d(1, 0, 0, -5deg)",
      //   duration: 0.2,
      // });

      // await gsap.to(element, {
      //   transform: "perspective(400px)",
      //   duration: 0.2,
      //   clearProps: "all",
      // });

      //console.log("b");
      //
      // animateCSS(, "flipInY").then(() => {

      // });
    },
    // eslint-disable-next-line no-unused-vars
    leaveCard(index) {
      //console.log(index);
      //gsap.to(`.cards .__card_${index}`, { rotation: 0, duration: 1 });
      this.overCard = -1;
    },
    onAppSelect(card) {
      //console.log(card, "card");
      if (card.appId === "air") {
        //空压在线则详情页
        this.$router.push(`/Detail/${card.appId}`);
      } else {
        //跳转到对应的应用
        JumpToApplication(card, this.company.managerPhone, this);
      }
    },
  },
  computed: mapState({
    company: (state) => state.customer.self,
    self: (state) => state.customerUser.self,
  }),
};
</script>

<style lang="scss" scoped>
.body {
  margin-top: 32px;
  flex-shrink: 0; //禁止被压缩
  display: flex;
  //display: block;
  .left {
    flex-shrink: 0; //禁止被压缩
    width: 180px;
    //background-color: greenyellow;
    .menu {
      .item {
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
      }
      .subject {
        margin-left: 17px;
      }
      .num {
        margin-right: 20px;
      }

      .active {
        background-color: rgba(255, 255, 255, 0.06);
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-left: 2px;
        border-left-style: solid;
        border-left-color: #0068ff;
      }

      .selected {
        @extend .active;
      }
    }
  }
  .right {
    margin-left: 12px;
    flex: auto;
    box-sizing: border-box;
    //background-color: green;
    .cards {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .card {
        //box-sizing: border-box;
        // border-style: solid;
        // border-color: red;
        // border-width: 1px;
        //width: 25%;
        margin-right: 70px;
        margin-bottom: 56px;

        width: 320px;
        height: 390px;
        background: #1b1b1b;

        border: 2px solid transparent; //先把border只为透明
        //box-shadow: 0px 0px 21px 0px rgba(15, 22, 34, 0.25);//原效果
        box-shadow: 10px 10px 10px 0px black; //立体效果加明显
        border-radius: 4px;

        // .card-item {
        //   width: 320px;
        //   height: 390px;
        //   background-color:red;
        //   border-width: 1px;
        // }

        .card-item {
          position: relative;
          .star {
            position: absolute;
            width: 32px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            background: rgba(15, 20, 28, 0.57);
            border-radius: 2px;
            top: 0px;
            left: 0px;
            font-size: 16px;
          }
          .caption {
            position: absolute;
            bottom: 20px;
            left: 20px;
            font-size: 19px;
            font-weight: bold;
            color: #fffefe;
          }
        }
      }
      @media screen and (min-width: 1410px) and (max-width: 1500px) {
        //小于等于
        .card:nth-child(3n + 3) {
          margin-right: 0 !important;
        }
        // .card:nth-child(4n + 4) {
        //   margin-right: 0 !important;
        // }
      }
      // @media screen and (min-width: 1800px){
      //   .card:nth-child(4n + 4) {
      //     margin-right: 0 !important;
      //   }
      // }
      //呼吸灯关键帧
      @keyframes breath {
        0% {
          //opacity: 0.4;
          box-shadow: 0 1px 2px rgba(0, 147, 223, 0.4),
            0 1px 1px rgba(0, 147, 223, 0.1) inset;
        }
        100% {
          //opacity: 1;
          border: 2px solid rgba(59, 235, 235, 0.7);
          box-shadow: 0 1px 30px #0093df, 0 1px 20px #0093df inset;
        }
      }

      .active {
        //width: 320px;
        //height: 390px;
        //background: #1B1B1B;
        border-color: #3c8cff; //修改border以及渐变的颜色
        box-shadow: 0px 0px 21px 0px #3c8cff;

        //启用呼吸灯帧动画
        animation-timing-function: ease-in-out;
        animation-name: breath;
        animation-duration: 1000ms;
        animation-iteration-count: infinite;
        animation-direction: alternate;

        //border-radius: 4px;
      }
    }
  }
}
</style>
