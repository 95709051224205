/** 部门所有接口 */
import request from '@/utils/request'

const BASE_PATH = '/department'

/** 新增部门 */
export const addDepartment = async (form) => {
  return request.post(`${BASE_PATH}`,  form)
}
// ?parentId=${form.parentId}&name=${form.name}
/** 编辑 */
export const editDepartment = async (id,params) => {
  return request.put(`${BASE_PATH}/${id}`, params)
}
/** 删除 */
export const delDepartment = async (id) => {
  return request.delete(`${BASE_PATH}/${id}`)
}
/** 获取部门树形结构 */
export const departmentTree = async () => {
  return request.get(`${BASE_PATH}/tree`)
}
/** 获取部门树形结构 */
export const departmentList = async () => {
  return request.get(`${BASE_PATH}/list`)
}
/** 获取部门树形结构 */
export const departmentListSimple = async () => {
  return request.get(`${BASE_PATH}/departmentList`)
}