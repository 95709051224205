<template>
  <span>
    <el-button @click="logout">
      退出
    </el-button>
  </span>
</template>
<script>
import AuthClient from "@/utils/AuthClient";
export default {
  methods: {
    //退出
    async logout() {
      try {
        await this.$confirm("确认退出?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
        AuthClient.getInstance().logout();
      } catch (err) {
        if (err !== "cancel") {
          throw err;
        }
      }
    },
  },
};
</script>
