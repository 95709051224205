import { request } from "@/utils/request";
import { loading } from "@/utils/index";

const BASE_PATH = "/customerUser";

//客户用户
class Api {
  //获取客户用户自身的信息
  @loading({ delay: 300 })
  async self() {
    return request.get(`${BASE_PATH}/self`);
  }

  /** 客户用户信息详情 */
  @loading({ delay: 0 })
  async detail(muid) {
    return request.get(`${BASE_PATH}/mgmt/${muid}`);
  }

  //新增客户用户
  @loading()
  async add(departmentId, form) {
    return request.post(
      `${BASE_PATH}/mgmt/create?departmentId=${departmentId}`,
      form
    );
  }

  //修改客户用户自身的信息
  @loading()
  async update(muid, departmentId, form) {
    return request.put(
      `${BASE_PATH}/mgmt/${muid}?departmentId=${departmentId}`,
      form
    );
  }

  //修改客户用户自身的信息
  @loading()
  async updateSelf(form) {
    return request.put(`${BASE_PATH}/updateSelf`, form);
  }

  //更新手机号
  @loading()
  async updatePhone(form) {
    return request.put(`${BASE_PATH}/updatePhone`, form);
  }

  //更新密码
  @loading()
  async updatePwd(form) {
    return request.put(`${BASE_PATH}/updatePwd`, form);
  }

  //分页获取
  @loading()
  async getPageList(params) {
    return request.get(`${BASE_PATH}/mgmt/page`, { params: params });
  }

  @loading()
  async bindAuthorityGroup(form) {
    return request.post(`${BASE_PATH}/mgmt/bindAuthorityGroup`, form);
  }

  @loading()
  async enable(muid, params) {
    return request.get(`${BASE_PATH}/mgmt/${muid}/enable`, { params: params });
  }

  /** 删除 */
  @loading()
  async del(muid) {
    return request.delete(`${BASE_PATH}/mgmt/${muid}`);
  }

  /** 删除 */
  @loading()
  async transferDepartmentAdmin(code, muid) {
    return request.put(
      `${BASE_PATH}/mgmt/transferDepartmentAdmin?code=${code}&muid=${muid}`
    );
  }
}

export default new Api();
