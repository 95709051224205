var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-subject',{attrs:{"subject":_vm.$route.query.mode === 'add' ? '新增员工' : '修改员工'}},[_c('div',{staticClass:"form-panel"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"150px","disabled":_vm.disabled}},[_c('el-form-item',{attrs:{"label":"员工姓名","prop":"name","rules":[
          {
            required: true,
            message: '员工姓名不能为空',
            trigger: 'change',
          },
        ]}},[_c('el-input',{attrs:{"maxlength":30,"placeholder":_vm.disabled ? '' : '请输入员工姓名'},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{attrs:{"label":"所在部门","prop":"departmentId","rules":[
          { required: true, message: '请选择所在部门', trigger: 'change' },
        ]}},[_c('q-select',{attrs:{"schema":{
            disabled: true,
            xType: 'q-select',
            placeholder: '请选择所在部门',
            clearable: false,
            opts: _vm.deptOpts,
            onSelectChange: _vm.onSelectChange,
          }},model:{value:(_vm.form.departmentId),callback:function ($$v) {_vm.$set(_vm.form, "departmentId", $$v)},expression:"form.departmentId"}})],1),_c('el-form-item',{attrs:{"label":"手机号","prop":"phone","rules":[
          { required: true, message: '手机号不能为空', trigger: 'change' },
          {
            pattern: _vm.regexs.mobile,
            message: '请输入正确的手机号',
            trigger: 'change',
          },
        ]}},[_c('el-input',{attrs:{"maxlength":30,"placeholder":_vm.disabled ? '' : '请输入手机号'},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"label":"是否为管理员","prop":"departmentAdmin"}},[_c('el-radio-group',{attrs:{"disabled":_vm.departmentAdminDisabled},model:{value:(_vm.form.departmentAdmin),callback:function ($$v) {_vm.$set(_vm.form, "departmentAdmin", $$v)},expression:"form.departmentAdmin"}},[_c('el-radio',{attrs:{"label":true}},[_vm._v("是")]),_c('el-radio',{attrs:{"label":false}},[_vm._v("否")])],1)],1),_c('el-form-item',{attrs:{"label":" 是否为演示账号","prop":"showMode","rules":[
          {
            required: true,
            message: '请选择是否为演示账号',
            trigger: 'change',
          },
        ]}},[_c('el-radio-group',{model:{value:(_vm.form.showMode),callback:function ($$v) {_vm.$set(_vm.form, "showMode", $$v)},expression:"form.showMode"}},[_c('el-radio',{attrs:{"label":true}},[_vm._v("是")]),_c('el-radio',{attrs:{"label":false}},[_vm._v("否")])],1)],1),_c('el-form-item',{attrs:{"label":"邮箱","prop":"email","rules":[
          {
            type: 'email',
            message: '请输入正确的邮箱地址',
            trigger: 'change',
          },
        ]}},[_c('el-input',{model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('el-form-item',{attrs:{"label":"性别","prop":"gender"}},[_c('q-select',{attrs:{"schema":{
            xType: 'q-select',
            placeholder: _vm.disabled ? '' : '请选择性别',
            opts: [
              {
                label: '男',
                value: 1,
              },
              {
                label: '女',
                value: 2,
              },
            ],
          }},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}})],1),_c('el-form-item',{attrs:{"label":"通讯地址","prop":"location"}},[_c('q-location-cascader',{attrs:{"schema":{
            placeholder: _vm.disabled ? '' : '请选择通讯地址',
            onClear: () => {
              _vm.form.address = null;
            },
          }},model:{value:(_vm.form.location),callback:function ($$v) {_vm.$set(_vm.form, "location", $$v)},expression:"form.location"}})],1),_c('el-form-item',{attrs:{"label":"","prop":"address","rules":[
          {
            required: _vm.$options.filters._location_has_value(_vm.form.location),
            message: '请输入完整的地址',
            trigger: 'change',
          },
        ]}},[_c('el-input',{attrs:{"maxlength":50,"disabled":_vm.$route.query.mode === 'view' ||
              !_vm.$options.filters._location_has_value(_vm.form.location),"type":"textarea","rows":4},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})],1)],1),_c('div',{staticClass:"form-button"},[(_vm.$route.query.mode !== 'view')?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onSubmit}},[_vm._v("确定")]):_vm._e(),_c('el-button',{on:{"click":function($event){return _vm.$back()}}},[_vm._v("返回")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }