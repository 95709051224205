<template>
  <div class="search-grid">
    <div class="search-grid-tool-bar">
      <el-button
        type="primary"
        v-if="$route.query.level === '1' || $route.query.level === '2'||self.admin"
        @click="
          () => {
            $router.push({
              name: 'StaffManageEdit',
              query: {
                mode: 'add',
                dep: selectedId,
              },
            });
          }
        "
      >
        + 新增员工
      </el-button>
    </div>
    <div>
      <staff-manage-search-form @onSearch="onSearch" />
    </div>
    <q-grid-view
      ref="gridview"
      :schema="{
        pagingEnable: true,
        autoLoad: false,
        getPageList: getPageList,
      }"
    >
      <!-- <el-table-column prop="id" label="id" width="180"> </el-table-column> -->

      <el-table-column prop="name" label="员工姓名"> </el-table-column>

      <el-table-column prop="phone" label="手机号码"> </el-table-column>

      <el-table-column prop="department" label="部门">
        <template slot-scope="scope">
          <span>
            {{ scope.row | _path("department.name") }}
          </span>
        </template>
      </el-table-column>


      <el-table-column prop="departmentAdmin" label="部门管理员">
        <template slot-scope="scope">
          <span>
            {{ scope.row.departmentAdmin?'是':'否' }}
          </span>
        </template>
      </el-table-column>

      <el-table-column prop="disabled" label="账号状态">
        <template slot-scope="scope">
          <span>
            {{ scope.row.disabled ? "已禁用" : "已启用" }}
          </span>
        </template>
      </el-table-column>

      <el-table-column align="right" label="操作" width="380px">
        <!-- <template slot="header" slot-scope="scope">
        <el-input
          v-model="search"
          size="mini"
          placeholder="输入关键字搜索"/>
      </template> -->
        <template slot-scope="scope">
          <el-button @click="handleView(scope.$index, scope.row)"
            >查看</el-button
          >
          <el-button
            v-if="hasRight(scope.row)"
            @click="handleEdit(scope.$index, scope.row)"
            >编辑</el-button
          >
          <el-button
            v-if="hasRight(scope.row)"
            @click="handleSetAuthority(scope.$index, scope.row)"
            >设置权限</el-button
          >
          <el-button
            v-if="hasRight(scope.row)"
            @click="handleDisabled(scope.$index, scope.row)"
            >{{ scope.row.disabled ? "启用" : "禁用" }}</el-button
          >
          <!-- <el-button @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          > -->

          <el-button
            v-if="showTransfer(scope.$index, scope.row)"
            :disabled="scope.row.disabled"
            @click="handleTransfer(scope.$index, scope.row)"
            >移交</el-button
          >
        </template>
      </el-table-column>
    </q-grid-view>

    <div>
      <el-dialog
        :before-close="closeAuthorityAndReset"
        :modal="true"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        title="设置权限"
        :visible.sync="dialogVisible"
      >
        <el-form ref="authForm" :model="authForm" label-width="150px">
          <el-form-item
            :key="authority.name"
            v-for="authority in allAuthoritiesFields"
            :rules="[]"
            :prop="authority.name"
          >
            <div>
              {{ authority.name }}
            </div>
            <div style="margin: 15px 0;"></div>
            <el-radio-group
              style="line-height:40px"
              v-model="authForm[authority.name]"
            >
              <el-radio
                v-for="opt in authority.opts"
                @click.native.prevent="
                  onRadioClick(opt.value, authority.name, opt)
                "
                :label="opt.value"
                :key="opt.value"
                :disabled="!(opt.subscribed && opt.leftDays > 0)"
                >{{ opt.label }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button @click="closeAuthorityAndReset">取 消</el-button>
          <el-button type="primary" @click="setAuthority"> 确 定</el-button>
        </div>
      </el-dialog>
    </div>

    <div>
      <el-dialog
        title="移交"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        :visible.sync="dialogVisibleTransfer"
        width="580px"
      >
        <el-form ref="phoneForm" :model="phoneForm" label-width="120px">
          <el-form-item label="手机号码" prop="originPhone">
            <el-row>
              <el-col :span="7">
                {{ phoneForm.originPhone }}
              </el-col>
              <el-col :span="6">
                <el-button
                  @click="sms()"
                  :disabled="phoneForm.sendButtonDisabled"
                  >{{ phoneForm.sendButtonText }}</el-button
                >
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item
            label="验证码"
            prop="captcha"
            :rules="[
              { required: true, message: '请输入验证码', trigger: 'change' },
            ]"
          >
            <el-input
              v-model="phoneForm.captcha"
              placeholder="请输入验证码"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="transferDepartmentAdmin"
              >确定</el-button
            >
            <el-button @click="dialogVisibleTransfer = false">取消</el-button>
          </el-form-item>
        </el-form>

        <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span> -->
      </el-dialog>
    </div>
  </div>
</template>

<script>
import StaffManageSearchForm from "./StaffManageSearchForm";
import api from "@/api/customerUser";
import captchaApi from "@/api/captcha";
import customerAuthorityGroupApi from "@/api/customerAuthorityGroup";
import { sleep } from "@/utils/index";
//import customerUserApi from "@/api/customerUser";
import Enumerable from "linq";
import BizCode from "@/utils/BizCode";
import AuthClient from "@/utils/AuthClient";
import Vue from "vue";
import { mapState } from "vuex";

import _ from "lodash";

export default {
  components: {
    StaffManageSearchForm,
  },
  props: {
    //选中的树节点的Id
    selectedId: {
      type: Number,
      default: () => -1,
    },
  },
  async mounted() {
    // const grid = this.$refs["gridview"];
    // grid.setQueryParams(_.cloneDeep({ departmentId: 230 }));
    // grid.syncServer();
  },

  data() {
    return {
      api: api,
      dialogVisible: false,
      dialogVisibleTransfer: false,
      allAuthoritiesFields: [],
      authForm: {},
      phoneForm: {
        transferMuid: null,
        originPhone: "",
        captcha: "",
        sendButtonDisabled: false,
        sendButtonText: "获取验证码",
      },
    };
  },
  //还原query参数
  watch: {
    selectedId: {
      // eslint-disable-next-line no-unused-vars
      handler(val, oldVal) {
        // if (val !== undefined && val !== null && val > 0) {
        //   this.$nextTick(() => {
        //     console.log("多次,这里会清空所有参数");

        //treeId变化会自动在路由上清空搜索参数
        this.$nextTick(() => {
          //有变化即刷新
          const grid = this.$refs["gridview"];
          grid.syncServer();
        });

        //   });
        // }
        // this.searchForm.page = parseInt(p.page || 1);
        // this.searchForm.key = p.key || '';
        // this.searchForm.classification = p.classification || '';
        // this.searchForm.status = p.status || '';
        // this.doSearchForm = { ...this.searchForm };
        // this.currentPage = parseInt(p.page ?? 1);
        // this.pageSize = parseInt(p.size ?? 5);
        // if (p?.queryParams !== null && p?.queryParams !== undefined) {
        //   this.queryParams = JSON.parse(window.decodeURI(p.queryParams));
        // } else {
        //   this.queryParams = {};
        // }
        //this.setOrderParams(p.orderParams ?? {});
        //this.fetchData();
        //this.syncServer(); //获取数据
      },
      //为什么不能立即执行呢
      immediate: true, //立即执行一次需要
    },
  },
  methods: {
    //是否显示移交
    // eslint-disable-next-line no-unused-vars
    showTransfer(index, row) {
      // // 不是租户负责人的部门管理员 且不是 下级的部门管理员 且不是自己用phone来判断
      // return (
      //   this.self.departmentAdmin &&
      //   !this.self.admin &&
      //   (row.accessWrite) &&
      //   row.phone !== this.self.phone
      // );

      //不是租户负责人的部门管理员 且不是 下级的部门管理员 且不是自己用phone来判断
      return (
        this.self.departmentAdmin &&
        !this.self.admin &&
        row.accessWrite &&
          (row.department?.id ?? "-1") === (this.self.department?.id ?? "-2") &&
        row.phone !== this.self.phone
      );
    },
    // //是否启用移交
    // // eslint-disable-next-line no-unused-vars
    // enableTransfer(index, row) {
    //   return !row.disabled;
    // },
    //是否有权限显示此按钮,默认不显示
    // eslint-disable-next-line no-unused-vars
    hasRight(row) {
      //return true;
      //租户负责人放开所有按钮权限
      if(this.self.admin){
        return true;
      }
      return row.accessWrite ?? false;
    },
    //可取消选中的radio
    onRadioClick(val, prop, opt) {
      if (opt.subscribed && opt.leftDays > 0) {
        if (val === this.authForm[prop]) {
          Vue.set(this.authForm, prop, null);
        } else {
          Vue.set(this.authForm, prop, val);
        }
      }
    },

    //处理禁用启用问题
    async handleDisabled(index, row) {
      try {
        await this.$confirm(`确认${row.disabled ? "启用" : "禁用"}?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
        //请求服务器
        const result = await api.enable(row.muid, { disabled: !row.disabled });
        if (result.code === BizCode.OK) {
          //若删除成功则刷新数据库
          const grid = this.$refs["gridview"];
          grid.syncServer();
        }
      } catch (err) {
        //取消按钮
        if (err !== "cancel") {
          throw err;
        }
      }
    },
    //删除
    // eslint-disable-next-line no-unused-vars
    async handleDelete(index, row) {
      try {
        await this.$confirm("确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
        //请求服务器
        const result = await api.del(row.muid);
        if (result.code === BizCode.OK) {
          //若删除成功则刷新数据库
          const grid = this.$refs["gridview"];
          grid.syncServer();
        }
      } catch (err) {
        //取消按钮
        if (err !== "cancel") {
          throw err;
        }
      }
    },
    //搜索
    onSearch(formData) {
      const grid = this.$refs["gridview"];
      grid.currentPage = 1;
      grid.setQueryParams({
        ..._.cloneDeep({ ...formData }),
      });
      grid.syncServer();
    },
    //分页获取数据
    getPageList(p) {
      if (
        this.selectedId !== undefined &&
        this.selectedId !== null &&
        this.selectedId > 0
      ) {
        //这里的selectedId永远是tree当前的选中Id
        let args = { ...p, ...{ departmentId: this.selectedId } };
        console.log(args, "args..................");
        return api.getPageList(args);
      } else {
        return [];
      }
    },
    //编辑
    // eslint-disable-next-line no-unused-vars
    handleEdit(index, row) {
      this.$router.push({
        name: "StaffManageEdit",
        query: {
          mode: "update",
          muid: row.muid,
        },
      });
    },
    //编辑
    // eslint-disable-next-line no-unused-vars
    handleView(index, row) {
      this.$router.push({
        name: "StaffManageEdit",
        query: {
          mode: "view",
          muid: row.muid,
        },
      });
    },

    //移交按钮
    // eslint-disable-next-line no-unused-vars
    async handleTransfer(index, row) {
      try {
        await this.$confirm(
          `移交完成后,您将失去所有应用权限!确认是否将管理员移交给${row.name}(${row.phone})?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        );

        //弹出个新框
        this.phoneForm.transferMuid = row.muid; //移交人的muid
        this.phoneForm.originPhone = this.self.phone; //设置手机号
        this.phoneForm.captcha = ""; //清空上次的验证码
        this.dialogVisibleTransfer = true;
      } catch (err) {
        //取消按钮
        if (err !== "cancel") {
          throw err;
        }
      }
    },
    async sms() {
      try {
        const response = await captchaApi.sms({
          phone: this.phoneForm.originPhone,
        });
        //发送成功
        console.log(response, "response");
        //等待60秒
        let originText = this.phoneForm.sendButtonText;
        this.phoneForm.sendButtonDisabled = true;
        let count = 60;
        while (count > 0) {
          this.phoneForm.sendButtonText = `等待${count}秒`;
          count--;
          //停一秒
          await sleep(1000);
        }
        this.phoneForm.sendButtonText = originText;
        this.phoneForm.sendButtonDisabled = false;
      } catch (err) {
        //发送失败
        console.log(err, "发送失败");
      }
    },
    transferDepartmentAdmin() {
      this.$refs["phoneForm"].validate(async (valid) => {
        if (valid) {
          //验证成功
          const result = await api.transferDepartmentAdmin(
            this.phoneForm.captcha,
            this.phoneForm.transferMuid
          );
          if (result.code === BizCode.OK) {
            //短信验证码检查成功更新手机号
            // this.form.managerPhone = this.phoneForm.newPhone;
            // this.phoneForm.originPhone = this.phoneForm.newPhone;
            // this.phoneForm.captcha = "";
            // this.phoneForm.newPhone = "";
            // this.phoneForm.sendButtonDisabled=false;
            // this.phoneForm.sendButtonText="获取验证码";
            this.dialogVisible = false;
            //退出
            AuthClient.getInstance().logout();
          }
          return true;
        } else {
          //验证失败
          return false;
        }
      });
    },

    //设置权限弹出窗
    // eslint-disable-next-line no-unused-vars
    async handleSetAuthority(index, row) {
      this.dialogVisible = true;

      window.setTimeout(async () => {
        const allAuthoritiesResult = await customerAuthorityGroupApi.fitCurrentUser(row.muid);
        if (allAuthoritiesResult.code === BizCode.OK) {
          const allAuthorities = allAuthoritiesResult.data;
          let allAuthoritiesFields = Enumerable.from(allAuthorities)
            .groupBy("$.category", null, (key, group) => {
              return {
                name: key,
                opts: group
                  .select((r) => {
                    return {
                      id: r.id,
                      label: r.name,
                      value: r.groupUid,
                      subscribed: r.application?.subscribed,
                      leftDays: r.application?.leftDays,
                    };
                  })
                  .toArray(),
              };
            })
            .toArray();
          console.log(allAuthoritiesFields, "allAuthoritiesFields");
          this.allAuthoritiesFields = allAuthoritiesFields;

          for (let field of allAuthoritiesFields) {
            console.log(field, "field");

            //选中的元素
            const selected = Enumerable.from(field.opts).firstOrDefault((o) =>
              Enumerable.from(row.groupUidList).any((g) => o.value === g)
            );

            //设置选中值
            if (selected !== undefined && selected !== null) {
              //this.authForm[field.name] = selected.value;
              Vue.set(this.authForm, field.name, selected.value); //只有这样才可以保证radioboxes可修改
            }
          }

          this.authForm["muid"] = row.muid;
        }
      }, 0);
    },

    //this.authForm
    async setAuthority() {
      const form = this.$refs["authForm"];
      // const formData = form.getData();
      const { muid, ...fields } = this.authForm;
      console.log(this.authForm, "this.authForm");
      form.validate(async (valid) => {
        console.log(valid, "valid");
        if (valid) {
          let groupUidList = [];

          const keys = Object.keys(fields);
          for (let key of keys) {
            const item = fields[key];
            groupUidList.push(item);
          }

          //过滤null和undefined值
          groupUidList = groupUidList.filter(
            (g) => g !== undefined && g !== null
          );

          console.log(groupUidList, "groupUidList");

          const result = await api.bindAuthorityGroup({
            muid: muid,
            groupUidList,
          });

          console.log(result, "result");

          if (result.code === BizCode.OK) {
            const grid = this.$refs["gridview"];
            grid.syncServer();
            this.closeAuthorityAndReset();
          }
        }

        //if (valid) {
        //   const grid = findComponentById(
        //     `${this.schema.subjectKey}-data-grid`,
        //     this
        //   );
        //   grid.setQueryParams(searchFrm.getData());
        //   grid.syncServer();
        // }
      });
    },

    //关闭并重置
    closeAuthorityAndReset() {
      //clean up
      console.log("clean up");
      this.authForm = {};
      this.allAuthoritiesFields = [];
      if (this.$refs["authForm"]) {
        this.$refs["authForm"].resetFields();
      }
      this.dialogVisible = false;
    },
  },
  computed: mapState({
    company: (state) => state.customer.self,
    self: (state) => state.customerUser.self,
  }),
};
</script>

<style lang="scss" scoped>
.search-grid {
  align-self: stretch;
  margin: 20px;
  .search-grid-tool-bar {
    height: 50px;
    line-height: 50px;
    text-align: right;
  }
}
</style>
