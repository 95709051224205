<script>
// import MenuBar from "@/components/MenuBar.vue";
// import QSearchBar from "@/components/QSearchBar.vue";
// import QDialog from "@/components/QDialog.vue";
// import QForm from "@/components/QForm.vue";
// import QPage from "@/components/QPage.vue";
// import QButton from "@/components/QButton.vue";
// import QCrud from "@/components/QCrud.vue";
// import QToolBar from "@/components/QToolBar.vue";
// import QRow from "@/components/QRow.vue";
// import QCol from "@/components/QCol.vue";
// import QContainer from "@/components/QContainer.vue";
import QSelect from "@/components/QSelect.vue";
// import QDataGrid from "@/components/QDataGrid.vue";
// import QField from "@/components/QField.vue";
import QLocationCascader from "@/components/QLocationCascader.vue";
import QIndustryCascader from "@/components/QIndustryCascader.vue";
import QCheckBoxGroup from "@/components/QCheckBoxGroup.vue";
// import QInputNumber from "@/components/QInputNumber.vue";
// import QVersionList from "@/components/QVersionList.vue";
// import QImageUpload from "@/components/QImageUpload.vue";
// import QCheckBoxes from "@/components/QCheckBoxes.vue";
import QGridView from "@/components/QGridView.vue";
//import QSearchForm from "@/components/QSearchForm.vue";

import QDropdownNav from "@/components/QDropdownNav.vue";
import QLogout from "@/components/QLogout.vue";
import QSubject from "@/components/QSubject.vue";

export default {
  install(Vue) {
    // Vue.component("q-menu-bar", MenuBar);
    // Vue.component("q-tool-bar", QToolBar);
    // Vue.component("q-search-bar", QSearchBar);
    // Vue.component("q-dialog", QDialog);
    // Vue.component("q-form", QForm);
    // Vue.component("q-page", QPage);
    // Vue.component("q-button", QButton);
    // Vue.component("q-crud", QCrud);
    // Vue.component("q-row", QRow);
    // Vue.component("q-col", QCol);
    // Vue.component("q-container", QContainer);
    Vue.component("q-select", QSelect);
    // Vue.component("q-data-grid", QDataGrid);
    // Vue.component("q-field", QField);
    Vue.component("q-location-cascader", QLocationCascader);
    Vue.component("q-industry-cascader", QIndustryCascader);
    Vue.component("q-checkbox-group", QCheckBoxGroup);
    // Vue.component("q-input-number", QInputNumber);
    // Vue.component("q-version-list", QVersionList);
    // Vue.component("q-image-upload", QImageUpload);
    // Vue.component("q-checkboxes", QCheckBoxes);
    Vue.component("q-grid-view", QGridView);
    //Vue.component("q-search-form", QSearchForm);
    Vue.component("q-dropdown-nav", QDropdownNav);
    Vue.component("q-logout", QLogout);
    Vue.component("q-subject", QSubject);
  },
};
</script>
