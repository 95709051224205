<template>
  <div>
    <q-subject subject="个人中心">
      <div class="form-panel">
        <el-form ref="form" :model="form" label-width="150px">
          <el-form-item
            label="公司名称"
            prop="name"
            :rules="[
              { required: true, message: '公司名称不能为空', trigger: 'change' },
            ]"
          >
            <el-input
              v-model="form.name"
              :maxlength="30"
              placeholder="请输入公司名称"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="公司简称"
            prop="simpleName"
            :rules="[
              { required: true, message: '公司简称不能为空', trigger: 'change' },
            ]"
          >
            <el-input
              v-model="form.simpleName"
              :maxlength="30"
              placeholder="请输入公司简称"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="负责人"
            prop="managerName"
            :rules="[
              { required: true, message: '负责人不能为空', trigger: 'change' },
            ]"
          >
            <el-input
              v-model="form.managerName"
              :maxlength="30"
              placeholder="请输入负责人"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="负责人手机"
            prop="managerPhone"
            :rules="[
              {
                required: true,
                message: '请输入负责人手机',
                trigger: 'change',
              },
            ]"
          >
            <el-row>
              <el-col :span="5">
                {{ form.managerPhone }}
              </el-col>
              <el-col :span="2">
                <i class="el-icon-warning-outline"></i>
              </el-col>
              <el-col :span="3">
                <el-button @click="dialogVisible = true" type="text"
                  >修改</el-button
                >
              </el-col>
            </el-row>
          </el-form-item>

          <el-form-item
            label="企业邮箱"
            prop="email"
            :rules="[
              {
                type: 'email',
                message: '请输入正确的邮箱地址',
                trigger: 'change',
              },
            ]"
          >
            <el-input
              v-model="form.email"
              placeholder="请输入企业邮箱"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="公司电话"
            prop="phone"
            :rules="[
              {
                pattern: regexs.phoneRegWithArea2,
                message: '请输入正确的电话',
                trigger: 'change',
              },
            ]"
          >
            <el-input
              v-model="form.phone"
              placeholder="请输入公司电话"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="社会统一信用代码"
            prop="uscc"
            :rules="[
              {
                required: true,
                message: '社会统一信用代码不能为空',
                trigger: 'change',
              },
              {
                pattern: regexs.uscc,
                message: '请输入正确的社会统一信用代码',
                trigger: 'change',
              },
            ]"
          >
            <el-input
              v-model="form.uscc"
              placeholder="请输入社会统一信用代码"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="所属行业"
            prop="industry"
            :rules="[
              { required: true, message: '请选择所属行业', trigger: 'change' },
            ]"
          >
            <q-industry-cascader
              :schema="{ placeholder: '请选择所属行业' }"
              v-model="form.industry"
            ></q-industry-cascader>
          </el-form-item>
          <el-form-item
            label="租户传真"
            prop="fax"
            :rules="[
              {
                pattern: regexs.phoneRegWithArea2,
                message: '请输入正确的传真',
                trigger: 'change',
              },
            ]"
          >
            <el-input
              v-model="form.fax"
              placeholder="请输入租户传真"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="租户地址"
            prop="location"
            :rules="[
              { required: true, message: '请选择租户地址', trigger: 'change' },
            ]"
          >
            <q-location-cascader
              :schema="{
                placeholder: '请选择租户地址',
                onClear: () => {
                  form.address = null;
                },
              }"
              v-model="form.location"
            ></q-location-cascader>
          </el-form-item>
          <el-form-item
            label=""
            prop="address"
            :rules="[
              {
                required: $options.filters._location_has_value(form.location),
                message: '请输入完整的地址',
                trigger: 'change',
              },
            ]"
          >
            <el-input
              :maxlength="50"
              v-model="form.address"
              :disabled="
                $route.query.mode === 'view' ||
                  !$options.filters._location_has_value(form.location)
              "
              type="textarea"
              :rows="4"
            ></el-input>
          </el-form-item>
          <el-form-item
                  label="公司logo"
                  prop="logo"
          >
            <el-upload
                    :class="{hide:hideUpload}"
                    action="/api/file/upload"
                    list-type="picture-card"
                    :file-list="imagelist"
                    :limit="1"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                    :on-remove="handleRemove"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">确定</el-button>
            <el-button @click="$goHome()">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </q-subject>
    <el-dialog
      title="修改手机号码"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :visible.sync="dialogVisible"
      width="580px"
    >
      <el-form ref="phoneForm" :model="phoneForm" label-width="120px">
        <el-form-item label="原手机号码" prop="originPhone">
          <el-row>
            <el-col :span="7">
              {{ phoneForm.originPhone }}
            </el-col>
            <el-col :span="6">
              <el-button
                @click="sms()"
                :disabled="phoneForm.sendButtonDisabled"
                >{{ phoneForm.sendButtonText }}</el-button
              >
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item
          label="验证码"
          prop="captcha"
          :rules="[
            { required: true, message: '请输入验证码', trigger: 'change' },
          ]"
        >
          <el-input
            v-model="phoneForm.captcha"
            placeholder="请输入验证码"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="新手机号码"
          prop="newPhone"
          :rules="[
            { required: true, message: '请输入新手机号', trigger: 'change' },
            {
              pattern: regexs.mobile,
              message: '请输入正确的手机号',
              trigger: 'change',
            },
          ]"
        >
          <el-input
            v-model="phoneForm.newPhone"
            placeholder="请输入新手机号"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="changePhone">确定</el-button>
          <el-button @click="dialogVisible = false">取消</el-button>
        </el-form-item>
      </el-form>

      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import BizCode from "@/utils/BizCode";
import api from "@/api/customer";
import customerUserApi from "@/api/customerUser";
import captchaApi from "@/api/captcha";
import { sleep } from "@/utils/index";
import { regexs } from "@/utils/validate";
import AuthClient from "@/utils/AuthClient";

export default {
  async mounted() {
    const result = await api.self();
    console.log(result, "result");
    if (result.code === BizCode.OK) {
      const {
        name,
        simpleName,
        managerName,
        managerPhone,
        email,
        phone,
        uscc,
        primaryIndustryId,
        secondaryIndustryId,
        minorIndustryId,
        fax,
        province,
        city,
        district,
        address,
          logo
      } = result.data;

      this.form = {
        name,
        simpleName,
        managerName,
        managerPhone,
        email,
        phone,
        uscc,
        industry: [primaryIndustryId, secondaryIndustryId, minorIndustryId],
        fax,
        location: [province, city, district],
        address,
          logo
      };
        if (this.form.logo !== null && this.form.logo !== '') {
            this.imagelist.push({
                name: this.form.logo,
                url: this.form.logo
            })
            this.hideUpload = true
        }
      this.phoneForm.originPhone = managerPhone;
    }
  },
  data() {
    return {
      regexs: regexs,
      dialogVisible: false,
      form: {
        name: "",
        simpleName: "",
        managerName: "",
        managerPhone: "",
        email: "",
        phone: "",
        uscc: "",
        industry: [],
        fax: "",
        location: [],
        address: "",
        logo: null
      },
        imagelist: [],
        hideUpload: false,
        limitCount:1,
      phoneForm: {
        originPhone: "",
        captcha: "",
        newPhone: "",
        sendButtonDisabled: false,
        sendButtonText: "获取验证码",
      },
    };
  },

  methods: {
    async sms() {
      try {
        const response = await captchaApi.sms({
          phone: this.phoneForm.originPhone,
        });
        //发送成功
        console.log(response, "response");
        //等待60秒
        let originText = this.phoneForm.sendButtonText;
        this.phoneForm.sendButtonDisabled = true;
        let count = 60;
        while (count > 0) {
          this.phoneForm.sendButtonText = `等待${count}秒`;
          count--;
          //停一秒
          await sleep(1000);
        }
        this.phoneForm.sendButtonText = originText;
        this.phoneForm.sendButtonDisabled = false;
      } catch (err) {
        //发送失败
        console.log(err, "发送失败");
      }
    },
    changePhone() {
      this.$refs["phoneForm"].validate(async (valid) => {
        if (valid) {
          //验证成功
          const result = await customerUserApi.updatePhone({
            phone: this.phoneForm.newPhone,
            code: this.phoneForm.captcha,
          });
          if (result.code === BizCode.OK) {
            //短信验证码检查成功更新手机号
            this.form.managerPhone = this.phoneForm.newPhone;
            this.phoneForm.originPhone = this.phoneForm.newPhone;
            this.phoneForm.captcha = "";
            this.phoneForm.newPhone = "";
            // this.phoneForm.sendButtonDisabled=false;
            // this.phoneForm.sendButtonText="获取验证码";
            this.dialogVisible = false;
            //退出
            AuthClient.getInstance().logout();
          }
          return true;
        } else {
          //验证失败
          return false;
        }
      });
    },
      /** 删除 */
      handleRemove() {
          this.form.logo = null;
          this.imagelist = [];
          this.hideUpload = false;
      },
      /** s上传图片 */
      handleAvatarSuccess(res) {
          this.imagelist.push({ name: res.data.url, url: res.data.url });
          this.form.logo = res.data.url;
          this.hideUpload = true;
      },
      imgChange(file, fileList){
          this.hideUpload = fileList.length === 1;
          this.hideUpload = true;
      },
      beforeAvatarUpload(file) {
          const isLt50M = file.size / 1024 / 1024 < 50;
          var index = file.name.lastIndexOf(".");
          const str = file.name.substring(index + 1, file.name.length);
          if (["jpg", "jpeg", "gif", "bmp", "png"].indexOf(str) === -1) {
              this.$message.error("支持*.jpg;*.jpeg;*.gif;*.bmp;*.png;格式");
              return false;
          }
          if (!isLt50M) {
              this.$message.error("上传图片大小不能超过 50MB!");
          }
          return isLt50M;
      },
    onSubmit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          //验证成功
          const {
            name,
            simpleName,
            managerName,
            managerPhone,
            email,
            phone,
            uscc,
            industry = [],
            fax,
            location = [],
            address,
              logo
          } = this.form;
          const form = {
            name: name,
            simpleName: simpleName,
            managerName: managerName,
            managerPhone: managerPhone,
            email: email,
            phone: phone,
            uscc: uscc,
            primaryIndustryId: industry[0] ?? null,
            secondaryIndustryId: industry[1] ?? null,
            minorIndustryId: industry[2] ?? null,
            fax: fax,
            province: location[0] ?? null,
            city: location[1] ?? null,
            district: location[2] ?? null,
            address: address,
              logo:logo
          };
          const result = await api.updateSelf(form);
          if (result.code === BizCode.OK) {
            //服务器成功返回后
            this.$message.success("修改成功");
            //跳转Home
            this.$router.push({
              name: "Home",
            });
          }
          return true;
        } else {
          //验证失败
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-panel {
  margin-top: 50px;
  width: 600px;
}
.hide .el-upload--picture-card {
  display: none !important;
}
</style>
