<template>
  <div class="x-layout x-is-vertical matrix">
    <div class="x-header matrix-header">
      <div class="matrix-header-bar">
        <span class="matrix-header-bar-logo" @click="$goHome()">
          <img
            src="@/assets/style/images/logos/logo-company.png"
            width="47px"
            class="matrix-header-bar-logo-image"
          />
        </span>
        <span class="matrix-header-bar-title">
          应用矩阵
        </span>
        <span class="space"></span>
        <!-- <i class="icon iconfont icon-xinxi matrix-header-bar-right" />
        <i class="icon iconfont icon-tongzhi matrix-header-bar-right" /> -->
        <i class="icon iconfont icon-yonghu cycle matrix-header-bar-right" />
        <!-- <span class="matrix-header-bar-right">
          管理员
        </span> -->
        <span class="matrix-header-bar-right"> <q-dropdown-nav /></span>
        <!-- <q-logout /> -->
      </div>
      <!-- <div class="bar2">
        -----------------
      </div> -->
    </div>
    <div class="matrix-body x-layout x-is-vertical">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { playLoop } from "@/utils/index";
//块
//字符

//import { gsap } from "gsap";
// eslint-disable-next-line no-unused-vars
// @ is an alias to /src

export default {
  name: "Layout",
  async mounted() {
    //gsap.to(".logos .logo", { rotation: 360, duration: 1, stagger: 0.4 });
    this.life = true;
    playLoop(".matrix-header-bar-logo-image", "flip", this, 4000);
  },
  data() {
    return {};
  },
  destroyed() {
    this.life = false;
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
$bar1height: 75px;
// $bar2height: 56px;

.matrix {
  //background-color: #0f141c;
  background: linear-gradient(144deg, #0f1e35, #0f141c, #0f141c, #0d1d35);

  padding: 40px;
  padding-top: 11px; //hack算出来的 40 - （$bar1height-(字体高)18)/2
  min-width: 1200px;
  box-sizing: border-box;
  flex-shrink: 0;
  min-height: 100%;
  .matrix-header {
    flex-shrink: 0; //禁止被压缩
    //height: $bar1height + $bar2height + 2px+2px;
    height: $bar1height + 2px;
    //background-color: red;
    display: flex;
    flex-direction: column;
    justify-content: space-between; //上下对齐
    .matrix-header-bar {
      //background-color: hotpink;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .matrix-header-bar-logo {
        cursor: pointer;
        font-size: 20px;
        color: blue;
        //justify-self: start;
      }
      .matrix-header-bar-title {
        margin-left: 30px;
        font-size: 20px;
        //justify-self: start;
      }
      //matrix-header-bar-right
      .matrix-header-bar-right {
        margin-left: 20px;
      }
    }
  }
  // .matrix-body {
  //   //margin-top: 32px;
  //   //flex-shrink: 0; //禁止被压缩
  //   //display: flex;
  //   //display: block;
  // }
}
</style>
