<template>
  <q-subject subject="权限组设置">
    <div class="form-panel">
      <el-form ref="form" :model="form" label-width="150px">
        <el-form-item
          label="权限组名称"
          prop="name"
          :rules="[
            {
              required: true,
              message: '权限组名称不能为空',
              trigger: 'change',
            },
          ]"
        >
          <el-input
            v-model="form.name"
            :maxlength="30"
            placeholder="请输入权限组名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="应用"
          prop="appId"
          :rules="[
            { required: true, message: '请选择应用', trigger: 'change' },
          ]"
        >
          <q-select
            :schema="{
              xType: 'q-select',
              placeholder: '请选择应用',
              remoteOnce: getAllApplication,
              onSelectChange: onApplicationSelect,
            }"
            v-model="form.appId"
          />
        </el-form-item>
        <el-form-item
          label="选择菜单"
          prop="resources"
          ref="fieldResources"
          :rules="[
            { required: true, message: '请选择菜单', trigger: 'change' },
          ]"
        >
          <q-checkbox-group
            :schema="{ opts: this.opts }"
            v-model="form.resources"
            placeholder="请选择菜单"
          ></q-checkbox-group>
        </el-form-item>
      </el-form>
      <div class="form-button">
        <el-button
          type="primary"
          @click="onSubmit"
          v-if="$route.query.mode !== 'view'"
          >确定</el-button
        >
        <el-button @click="$back()">返回</el-button>
      </div>
    </div>
  </q-subject>
</template>

<script>
import BizCode from "@/utils/BizCode";
import api from "@/api/customerAuthorityGroup";
import applicationApi from "@/api/application";

export default {
  async mounted() {
    if (
      this.$route?.query?.mode === "update" ||
      this.$route?.query?.mode === "view"
    ) {
      //修改或更新,需先获取需要的信息
      const result = await api.detail(this.$route?.query?.uid);
      console.log(result, "result");
      if (result.code === BizCode.OK) {
        const { data } = result;
        const { name, application, resources } = data;
        //组件初始化时就会调用getAllApplication()填充select组件
        await this.onApplicationSelect(application?.appId);
        this.form = {
          name,
          appId: application?.appId,
          resources: (resources ?? []).map((r) => r.resource),
        };
      }
    }
  },
  data() {
    return {
      opts: [],
      form: {
        name: "",
        appId: null,
        resources: [],
      },
    };
  },
  methods: {
    async getAllApplication() {
      const result = await applicationApi.list();
      if (result.code === BizCode.OK) {
        return (result.data ?? [])
          .filter((m) => m.subscribed && m.leftDays > 0)
          .map((m) => {
            return { label: m.name, value: m.appId };
          });
      }
      return [];
    },
    async onApplicationSelect(val) {
      if (val) {
        const result = await api.listResources({ appId: val });
        if (result.code === BizCode.OK) {
          this.opts = (result.data ?? []).map((r) => {
            return { label: r.name, value: r.resource };
          });
        }
      } else {
        this.form.resources = [];
        this.opts = [];
        this.$nextTick(() => {
          this.$refs["fieldResources"].clearValidate();
        });
      }
    },
    onSubmit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          //验证成功
          const { name, appId, resources } = this.form;
          const form = {
            name: name,
            appId: appId,
            resources: resources,
          };
          let result = null;
          if (this.$route?.query?.mode === "add") {
            //新增
            result = await api.add(form);
          } else if (this.$route?.query?.mode === "update") {
            //修改
            result = await api.update(this.$route?.query?.uid, form);
          }

          if (result.code === BizCode.OK) {
            //服务器成功返回后
            console.log(result.code, "权限组这里准备返回");
            this.$back();
          }

          return true;
        } else {
          //验证失败
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-panel {
  margin-top: 50px;
  width: 600px;
}
.form-button {
  width: 600px;
  text-align: center;
  height: 80px;
  line-height: 80px;
}
</style>
