import customerApi from "@/api/customer";
import BizCode from "@/utils/BizCode";
const state = { self: { done: false } };

const mutations = {
  SET_SELF(state, self) {
    state.self = self;
  },
};

const actions = {
  // eslint-disable-next-line no-empty-pattern
  async self({ commit }) {
    const result = await customerApi.self();
    if (result.code === BizCode.OK) {
      //commit
      commit("SET_SELF", { ...result.data, done: true });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
